import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@services';

@Injectable()
export class ResearchPersonnelGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authService.user;
    if (user && user.isResearchPersonnel) {
      return true;
    } else if (user && user.isPatient) {
      this.router.navigate(['/patient-portal']);
      return false;
    } else {
      return this.restrict(state.url);
    }
  }

  restrict(redirectUrl: string): boolean {
    this.router.navigate(['/login'], {
      queryParams: { redirectUrl: redirectUrl },
    });
    return false;
  }
}
