import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { StatusService, AuthService } from '@services';

@Injectable()
export class ResearchOrganizationStatusGuard implements CanActivate {
  constructor(
    private statusService: StatusService,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.authService.user;
    if (user && user.isResearchPersonnel) {
      return this.statusService
        .getResearchOrganizationStatus()
        .toPromise()
        .then((_) => true)
        .catch((err) => {
          if (err.Status === 403 && err.Message === 'Terms not accepted') {
            this.router.navigate([
              '/terms',
              'personnel',
              { updated: err.PreviouslyAcceptedTerms },
            ]);
            return false;
          } else if (err.Status === 403 && err.Message === 'Access denied') {
            this.router.navigate(['/access-denied', { expired: err.Expired }]);
            return false;
          } else {
            console.error(err);
            return this.restrict(state.url);
          }
        });
    } else if (user && user.isPatient) {
      this.router.navigate(['/patient-portal']);
      return false;
    } else {
      return this.restrict(state.url);
    }
  }

  restrict(redirectUrl: string): boolean {
    this.router.navigate(['/login'], {
      queryParams: { redirectUrl: redirectUrl },
    });
    return false;
  }
}
