<ng-template #dialog>
  <ng-template tdLoading [tdLoadingUntil]="initialized">
    <div *ngIf="group && templates">
      <div>
        <mat-card-title fxLayout="row" fxLayoutGap="16px">
          <h4>{{ group.Date | tz: 'date' }}</h4>
          <span class="date-span">{{ group.Date | tz: 'time' }}</span>
        </mat-card-title>
      </div>
      <div class="pad-top-sm">
        <div
          fxLayout="row"
          [ngStyle]="{ 'max-height': h + 'px', 'max-width': w + 'px' }"
        >
          <ng-template let-template let-i="index" ngFor [ngForOf]="templates">
            <app-canvas-video
              #canvasVideo
              [template]="template"
              [charts]="charts[i]"
              [dimensions]="dims"
            ></app-canvas-video>
          </ng-template>
        </div>
        <mat-slider
          class="full-width"
          tickInterval="1"
          step="1"
          min="0"
          [max]="groups.length - 1"
          [(ngModel)]="pos"
          (change)="changeGroup($event.value)"
        ></mat-slider>
        <div fxLayoutAlign="center">
          <div
            fxLayout="row"
            fxLayoutAlign="space-around"
            style="width: 350px"
            style.xs="width: 250px"
          >
            <button class="grey-icon" mat-icon-button (click)="changeGroup(0)">
              <mat-icon>first_page</mat-icon>
            </button>
            <button
              class="grey-icon"
              mat-icon-button
              (click)="changeGroup(pos - 1)"
            >
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button class="grey-icon" mat-icon-button (click)="togglePlay()">
              <mat-icon>{{ isPlaying ? 'pause' : 'play_arrow' }}</mat-icon>
            </button>
            <button
              class="grey-icon"
              mat-icon-button
              (click)="changeGroup(pos + 1)"
            >
              <mat-icon>chevron_right</mat-icon>
            </button>
            <button
              class="grey-icon"
              mat-icon-button
              (click)="changeGroup(groups.length - 1)"
            >
              <mat-icon>last_page</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
