import { fromEvent as observableFromEvent } from 'rxjs';
import { distinctUntilChanged, map, debounceTime } from 'rxjs/operators';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { countryList } from '../timezone-picker/timezone-data';

interface Country {
  iso: string;
  name: string;
}

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['../shared.scss'],
})
export class CountrySelectorComponent implements AfterViewInit {
  @ViewChild('searchinput') searchinput!: ElementRef;
  _countries: Country[];
  countries: Country[];
  country!: Country;
  searchTerm!: string;
  @Input() searchPlaceholder = 'Search';
  @Input() disabled = false;
  @Input() placeholder = 'Choose country';
  @Input() set countryCode(iso2: string) {
    const result = this.countries.find((c) => c.iso === iso2);
    if (result) {
      this.country = result;
    }
  }
  @Output() countryCodeChange = new EventEmitter<string>();

  constructor() {
    this.countries = this._countries = Object.keys(countryList).map(
      (iso: string) => {
        return { iso: iso, name: countryList[iso] };
      }
    );
  }

  ngAfterViewInit() {
    observableFromEvent(this.searchinput.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        map((e: any) => e.target.value),
        distinctUntilChanged()
      )
      .subscribe(() => this.filter());
  }

  getCountryName() {
    return this.country.name;
  }

  filter() {
    this.countries = this._countries.filter(
      (s) =>
        s.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0 ||
        s.iso.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0
    );
  }

  focus() {
    this.clearSearch();
    this.searchinput.nativeElement.focus();
  }

  clearSearch() {
    this.searchTerm = '';
    this.countries = this._countries;
  }

  changeCountry(country: Country) {
    this.countryCodeChange.emit(country.iso);
  }
}
