import { Injectable } from '@angular/core';
import { Plan } from '@models';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  private _plans: Plan[] = [
    {
      name: 'basic',
      price: 499,
      currency: 'eur',
      popular: false,
      color: '#2dccd2',
      features: [
        {
          features: [
            {
              key: 'patient_year',
              tooltip: 'patient_year_tooltip',
              quantity: 50,
            },
          ],
        },
        {
          category: 'mapping',
          features: [
            { key: 'pain_drawings', tooltip: 'pain_drawings_tooltip' },
            {
              key: 'symptom_pain_quality',
              tooltip: 'symptom_pain_quality_tooltip',
            },
            { key: 'pain_intensity', tooltip: 'pain_intensity_tooltip' },
          ],
        },
        {
          category: 'body_templates',
          features: [
            { key: 'body_templates', tooltip: 'body_templates_tooltip' },
            { blank: true },
          ],
        },
        {
          category: 'real_time',
          features: [{ key: 'pain_area', tooltip: 'pain_area_tooltip' }],
        },
        {
          category: 'visualization',
          features: [
            { key: 'timeline', tooltip: 'timeline_tooltip' },
            { key: 'toggle', tooltip: 'toggle_tooltip' },
            { blank: true },
          ],
        },
        {
          category: 'exports',
          features: [
            { key: 'single_export', tooltip: 'single_export_tooltip' },
            { blank: true },
          ],
        },
        {
          category: 'data_tools',
          features: [
            {
              key: 'personnel_limit',
              tooltip: 'personnel_limit_tooltip',
              quantity: 2,
            },
            {
              key: 'multiple_languages',
              tooltip: 'multiple_languages_tooltip',
            },
            {
              key: 'patient_chart_limit',
              tooltip: 'patient_chart_limit_tooltip',
              quantity: 4,
            },
          ],
        },
      ],
    },
    {
      name: 'advanced',
      price: 999,
      currency: 'eur',
      popular: true,
      color: '#059e74',
      features: [
        {
          features: [
            {
              key: 'patient_year',
              tooltip: 'patient_year_tooltip',
              quantity: 1000,
            },
          ],
        },
        {
          category: 'mapping',
          features: [
            { key: 'pain_drawings', tooltip: 'pain_drawings_tooltip' },
            {
              key: 'symptom_pain_quality',
              tooltip: 'symptom_pain_quality_tooltip',
            },
            { key: 'pain_intensity', tooltip: 'pain_intensity_tooltip' },
          ],
        },
        {
          category: 'body_templates',
          features: [
            { key: 'body_templates', tooltip: 'body_templates_tooltip' },
            { blank: true },
          ],
        },
        {
          category: 'real_time',
          features: [{ key: 'pain_area', tooltip: 'pain_area_tooltip' }],
        },
        {
          category: 'visualization',
          features: [
            { key: 'timeline', tooltip: 'timeline_tooltip' },
            { key: 'toggle', tooltip: 'toggle_tooltip' },
            { key: 'regional_overlays', tooltip: 'regional_overlays_tooltip' },
          ],
        },
        {
          category: 'exports',
          features: [
            { key: 'single_export', tooltip: 'single_export_tooltip' },
            { key: 'bulk_export', tooltip: 'bulk_export_tooltip' },
          ],
        },
        {
          category: 'data_tools',
          features: [
            {
              key: 'personnel_limit',
              tooltip: 'personnel_limit_tooltip',
              quantity: 5,
            },
            {
              key: 'multiple_languages',
              tooltip: 'multiple_languages_tooltip',
            },
            {
              key: 'patient_chart_limit',
              tooltip: 'patient_chart_limit_tooltip',
              quantity: 8,
            },
          ],
        },
        {
          category: 'support',
          features: [
            { key: 'email_suppport', tooltip: 'email_suppport_tooltip' },
          ],
        },
      ],
    },
    {
      name: 'premium',
      price: 2749,
      currency: 'eur',
      color: '#05467F',
      popular: false,
      features: [
        {
          features: [
            {
              key: 'patient_year',
              tooltip: 'patient_year_tooltip',
              quantity: 10000,
            },
          ],
        },
        {
          category: 'mapping',
          features: [
            { key: 'pain_drawings', tooltip: 'pain_drawings_tooltip' },
            {
              key: 'symptom_pain_quality',
              tooltip: 'symptom_pain_quality_tooltip',
            },
            { key: 'pain_intensity', tooltip: 'pain_intensity_tooltip' },
          ],
        },
        {
          category: 'body_templates',
          features: [
            { key: 'body_templates', tooltip: 'body_templates_tooltip' },
            {
              key: 'extra_body_templates',
              tooltip: 'extra_body_templates_tooltip',
            },
          ],
        },
        {
          category: 'real_time',
          features: [{ key: 'pain_area', tooltip: 'pain_area_tooltip' }],
        },
        {
          category: 'visualization',
          features: [
            { key: 'timeline', tooltip: 'timeline_tooltip' },
            { key: 'toggle', tooltip: 'toggle_tooltip' },
            { key: 'regional_overlays', tooltip: 'regional_overlays_tooltip' },
          ],
        },
        {
          category: 'exports',
          features: [
            { key: 'single_export', tooltip: 'single_export_tooltip' },
            { key: 'bulk_export', tooltip: 'bulk_export_tooltip' },
          ],
        },
        {
          category: 'data_tools',
          features: [
            {
              key: 'personnel_limit',
              tooltip: 'personnel_limit_tooltip',
              quantity: 5,
            },
            {
              key: 'multiple_languages',
              tooltip: 'multiple_languages_tooltip',
            },
            {
              key: 'patient_chart_limit',
              tooltip: 'patient_chart_limit_tooltip',
              quantity: 8,
            },
          ],
        },
        {
          category: 'support',
          features: [
            { key: 'email_suppport', tooltip: 'email_suppport_tooltip' },
          ],
        },
      ],
    },
  ];

  get plans(): Plan[] {
    return this._plans;
  }
}
