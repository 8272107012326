<div *ngIf="!vertical">
  <div class="progress t-l-border-radius t-r-border-radius" fxLayout="row">
    <div
      class="determinate t-l-border-radius"
      [class.t-r-border-radius]="currentPainLevel >= 10"
      [style.width]="currentPainLevel * 10 + '%'"
    ></div>
    <span
      *ngIf="usualPainPlaceholder"
      class="pain-text pain-text-left unselectable"
      >{{ currentPainPlaceholder }}</span
    >
    <span class="pain-text pain-text-right unselectable">{{
      currentPainLevel < 10 ? currentPainLevel.toFixed(1) : currentPainLevel
    }}</span>
  </div>
  <div
    class="progress b-l-border-radius b-r-border-radius"
    [style.background-color]="'#BFD3F2'"
    fxLayout="row"
  >
    <div
      class="determinate b-l-border-radius"
      [class.b-r-border-radius]="usualPainLevel >= 10"
      [style.background-color]="'#7AA3E5'"
      [style.width]="usualPainLevel * 10 + '%'"
    ></div>
    <span
      *ngIf="usualPainPlaceholder"
      class="pain-text pain-text-left unselectable"
      >{{ usualPainPlaceholder }}</span
    >
    <span class="pain-text pain-text-right unselectable">{{
      usualPainLevel < 10 ? usualPainLevel.toFixed(1) : usualPainLevel
    }}</span>
  </div>
</div>
<div *ngIf="vertical" fxFlexFill fxLayout="row">
  <div id="outer" class="t-l-border-radius b-l-border-radius">
    <div class="pain-text vertical unselectable">
      {{
        currentPainLevel < 10 ? currentPainLevel.toFixed(1) : currentPainLevel
      }}
    </div>
    <div id="inner" [style.height]="currentPainLevel * 10 + '%'">
      <div></div>
    </div>
    <div
      *ngIf="currentPainPlaceholder"
      class="pain-text pain-text-bottom vertical unselectable"
    >
      {{ currentPainPlaceholder }}
    </div>
  </div>
  <div
    id="outer"
    class="t-r-border-radius b-r-border-radius"
    [style.background-color]="'#BFD3F2'"
  >
    <div class="pain-text vertical unselectable">
      {{ usualPainLevel < 10 ? usualPainLevel.toFixed(1) : usualPainLevel }}
    </div>
    <div
      id="inner"
      [style.background-color]="'#7AA3E5'"
      [style.height]="usualPainLevel * 10 + '%'"
    >
      <div></div>
    </div>
    <div
      *ngIf="usualPainPlaceholder"
      class="pain-text pain-text-bottom vertical unselectable"
    >
      {{ usualPainPlaceholder }}
    </div>
  </div>
</div>
