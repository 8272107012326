import { environment } from '@environment';
import { Template } from '@models';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Angulartics2 } from 'angulartics2';
import { fabric } from 'fabric';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  viewProviders: [TranslatePipe],
})
export class CanvasComponent implements OnInit {
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  isInitialized = false;
  activeOverlays: string[] = [];
  initializedOverlays: string[] = [];
  canvas!: fabric.Canvas;
  pan?: { x: number; y: number };
  isPanning = false;
  isZooming = false;
  prevZoomScale = 1;

  redoHolder: any[] = [];
  zoomStartScale!: number;
  maxZoom = 5;
  minZoom = 0.975;
  zoomLevel = this.minZoom;

  get freeDrawingBrush(): fabric.FreeDrawingBrush {
    return this.canvas?.freeDrawingBrush;
  }

  _color = '#8C1713'; // default color
  @Input() set color(color: string) {
    this._color = color;
    if (this.canvas && color) {
      this.freeDrawingBrush.color = color;
    }
  }

  @Input() template!: Template;

  _isDrawing = true;
  @Input() set isDrawing(d: boolean) {
    this._isDrawing = d;
    if (this.canvas) {
      this.canvas.isDrawingMode = d;
    }
  }

  _penSize!: number;
  @Input() set penSize(p: number) {
    this._penSize = p;
    if (this.canvas) {
      this.freeDrawingBrush.width = p;
    }
  }

  _height!: number;
  @Input() set height(height: number) {
    this._height = height;
    if (this.canvas && height) {
      const scale = height / this.canvas.getHeight();

      /** Change dimension of canvas */
      this.canvas.setHeight(height);

      if (this.canvas.backgroundImage) {
        (<fabric.Image>this.canvas.backgroundImage).scaleToHeight(
          this.canvas.getHeight()
        );
      }

      /** Rescale all objects in canvas */
      this.canvas.getObjects().forEach((e) => {
        const typeList: string[] = ['path', 'text', 'path-group'];
        if (
          e.top &&
          e.left &&
          e.scaleY &&
          e.scaleX &&
          e.type &&
          typeList.includes(e.type)
        ) {
          e.set({
            top: e.top * scale,
            left: e.left * scale,
            scaleY: e.scaleY * scale,
            scaleX: e.scaleX * scale,
          });
        }
      });

      /** Rescale all discarded objects */
      this.redoHolder.forEach((t) => {
        if (t.top && t.left && t.scaleY && t.scaleX && t.type) {
          t.set({
            top: t.top * scale,
            left: t.left * scale,
            scaleY: t.scaleY * scale,
            scaleX: t.scaleX * scale,
          });
        }
      });

      /** Render all objects in canvas */
      this.renderCanvas();
      this.resetZoom();
    }
  }

  _width!: number;
  @Input() set width(width: number) {
    this._width = width;
    if (this.canvas && width) {
      this.canvas.setWidth(width);
      this.resetZoom();
    }
  }

  @HostListener('pinchstart', ['$event'])
  onPinchstart(e: any) {
    this.isZooming = true;
    this.zoomStartScale = this.canvas.getZoom();
  }

  @HostListener('pinchend', ['$event'])
  onPinchend(e: any) {
    this.isZooming = false;
  }

  constructor(
    private angulartics2: Angulartics2,
    private translatePipe: TranslatePipe
  ) {}

  ngOnInit() {
    this.initCanvas();
  }

  initCanvas() {
    this.canvas = new fabric.Canvas(this.canvasRef.nativeElement);

    this.canvas.isDrawingMode = this._isDrawing;
    this.freeDrawingBrush.color = this._color;
    this.freeDrawingBrush.width = this._penSize;
    this.canvas.renderOnAddRemove = false;
    this.canvas.selection = false;

    fabric.Image.fromURL(
      environment.templatePath + this.template.FilePath,
      (img) => {
        img.lockMovementX = true;
        img.lockMovementY = true;
        img.lockScalingX = true;
        img.lockScalingY = true;
        img.lockRotation = true;
        img.selectable = false;

        this.canvas.setBackgroundImage(img, () => {
          (<fabric.Image>this.canvas.backgroundImage).scaleToHeight(
            this.canvas.getHeight()
          );

          if (this.template.Extra) {
            if (
              this.template.Extra.OverlayText &&
              this.template.Extra.OverlayText.length
            ) {
              for (const OverlayText of this.template.Extra.OverlayText) {
                const height = this.canvas.getHeight();
                const textScale = this.template.Height / height;
                const width = this.template.Width / textScale;

                let text: string = this.translatePipe.transform(
                  OverlayText.text
                );

                if (text && text.length) {
                  text = text.charAt(0);

                  // fix for Turkish
                  if (text === 'S') {
                    text = OverlayText.abbrivation;
                  }
                } else {
                  text = OverlayText.abbrivation;
                }

                const canvasText = new fabric.Text(text, {
                  fontSize: Math.round(height * OverlayText.fontSizeScale),
                  left: width * OverlayText.leftScale,
                  top: height * OverlayText.topScale,
                  fontFamily: OverlayText.fontFamily,
                });

                this.canvas.add(canvasText);
              }
            }
          }

          this.renderCanvas();
          this.isInitialized = true;
        });
      }
    );

    this.canvas.on('path:created', (event: any) => {
      if (this._isDrawing) {
        (<fabric.Path>event.path).set({
          opacity: 1,
          perPixelTargetFind: false,
          hasRotatingPoint: false,
          hasBorders: false,
          selectable: false,
          globalCompositeOperation: 'source-atop',
        });
        this.canvas.requestRenderAll();
      }
    });

    /** Mouse wheel zoom */
    this.canvas.on('mouse:wheel', (event: any) => {
      const point = new fabric.Point(event.e.layerX, event.e.layerY);
      const zoomIn = event.e.deltaY < 0;
      const delta = zoomIn
        ? this.canvas.getZoom() * 1.1
        : this.canvas.getZoom() / 1.1;
      this.zoom(point, delta);
      this.renderCanvas();
      this.angulartics2.eventTrack.next({
        action: zoomIn ? 'ZoomIn' : 'ZoomOut',
        properties: {
          label: 'mousewheel',
          category: 'Drawing',
          value: this.zoomLevel,
        },
      });
    });
  }

  move(x: number, y: number) {
    const delta = new fabric.Point(x, y);
    this.canvas.relativePan(delta);
    this.renderCanvas();
  }

  zoomToCenter(zoomLevel: number) {
    const center = new fabric.Point(
      this.canvas.getWidth() / 2,
      this.canvas.getHeight() / 2
    );
    const delta = this.limitZoom(this.canvas.getZoom() * zoomLevel);
    this.canvas.zoomToPoint(center, delta);
    this.renderCanvas();
  }

  drag(event: any): void {
    if (event && this.isInitialized && !this._isDrawing && !this.isZooming) {
      if (!this.pan) {
        this.pan = { x: event.srcEvent.x, y: event.srcEvent.y };
      }
      const delta = new fabric.Point(
        event.srcEvent.x - this.pan.x,
        event.srcEvent.y - this.pan.y
      );
      this.canvas.relativePan(delta);
      this.renderCanvas();
      this.pan = { x: event.srcEvent.x, y: event.srcEvent.y };
      if (event.isFinal) {
        this.pan = undefined;
      }
    }
  }

  pinch(event: any): void {
    if (event && this.isInitialized && !this._isDrawing && this.isZooming) {
      const point = new fabric.Point(event.srcEvent.x, event.srcEvent.y);
      // Remember canvas scale at gesture start
      const delta = this.zoomStartScale * event.scale;
      this.zoom(point, delta);
    }
  }

  zoom(point: any, delta: number): void {
    if (delta < this.minZoom) {
      this.resetZoom();
    } else {
      this.prevZoomScale = this.limitZoom(delta);
      this.canvas.zoomToPoint(point, this.prevZoomScale);
      this.renderCanvas();
    }
  }

  limitZoom(delta: number): number {
    this.zoomLevel = Math.max(Math.min(delta, this.maxZoom), this.minZoom);
    return this.zoomLevel;
  }

  undo(): void {
    if (this.canvas) {
      if (this.pathCount > 0) {
        const objs = this.canvas.getObjects('path');
        const obj = objs[objs.length - 1];
        this.redoHolder.push(obj);
        this.canvas.remove(obj);
        this.renderCanvas();
      }
    }
  }

  redo(): void {
    if (this.canvas) {
      if (this.redoHolder.length > 0) {
        this.canvas.getObjects().push(this.redoHolder.pop());
        this.renderCanvas();
      }
    }
  }

  resetZoom(): void {
    let c = this.canvas.getCenter();
    let left = -(c.left - this.getWidth() / 2);
    let top = (this.getHeight() * -(1 - 0.975)) / 2;
    this.canvas.absolutePan(new fabric.Point(left, top));
    this.canvas.setZoom(this.minZoom);
    this.renderCanvas();

    // hack for true center, first time (sometimes) only centers halfways
    c = this.canvas.getCenter();
    left = -(c.left - this.getWidth() / 2);
    top = (this.getHeight() * -(1 - 0.975)) / 2;
    this.canvas.absolutePan(new fabric.Point(left, top));
    this.canvas.setZoom(this.minZoom);
    this.renderCanvas();
  }

  get maxZommed(): boolean {
    return this.maxZoom <= this.zoomLevel;
  }

  get minZommed(): boolean {
    return this.minZoom >= this.zoomLevel;
  }

  get getRedoHolderCount(): number {
    return this.redoHolder.length;
  }

  get pathCount(): number {
    return this.canvas.getObjects('path').length;
  }

  getWidthFromHeight(heigth: number): number {
    let ratio = 2160 / 1135;
    if (this.template.Width && this.template.Height) {
      ratio = this.template.Height / this.template.Width;
    }
    return Math.round(heigth / ratio);
  }

  get JSONString(): string {
    if (
      !this.canvas.backgroundImage ||
      (<fabric.Image>this.canvas.backgroundImage).getSrc() === ''
    ) {
      throw new Error('Background image not present in canvas');
    }
    this.canvas.getObjects().forEach((o) => {
      if (o.top === -2.1610915492957754 && o.left === -2.1610915492957754) {
        // tslint:disable-next-line:no-debugger
        debugger;
        this.canvas.remove(o);
      }
    });

    const obj = this.canvasObject;

    try {
      obj.objects = obj.objects.filter(
        (o: any) => !['text', 'path-group'].includes(o.type)
      );
    } catch (e) {
      console.error(e);
    }

    return JSON.stringify(obj);
  }

  get chartObject(): any {
    return {
      Width: this.getWidth(),
      Height: this.getHeight(),
      Canvas: this.canvasObject,
    };
  }

  get canvasObject() {
    return this.canvas.toObject();
  }

  getHeight(): number {
    return this.canvas.getHeight();
  }

  getWidth(): number {
    return this.getWidthFromHeight(this.getHeight());
  }

  get isDrawn(): boolean {
    return this.pathCount > 0;
  }

  toggleOverlay(overlayType: string, callback: (error?: string) => void) {
    overlayType = overlayType.replace(' ', '_').toLowerCase();
    if (this.initializedOverlays.includes(overlayType)) {
      if (this.activeOverlays.includes(overlayType)) {
        this.canvas.getObjects('group').forEach((o: any) => {
          if (o.overlayType === overlayType) {
            o.opacity = 0;
          }
        });
        const index = this.activeOverlays.indexOf(overlayType);
        if (index > -1) {
          this.activeOverlays.splice(index, 1);
        }
      } else {
        this.canvas.getObjects('group').forEach((o: any) => {
          if (o.overlayType === overlayType) {
            o.opacity = 1;
            o.bringToFront();
          }
        });
        this.activeOverlays.push(overlayType);
      }

      this.renderCanvas();
      callback();
    } else {
      const url =
        environment.overlayPath +
        overlayType +
        '/' +
        this.template.FilePath.toLowerCase().replace('png', 'svg');
      fabric.loadSVGFromURL(url, (objects, options) => {
        if (objects) {
          objects.map(
            (o: any) => (o.stroke = this.getColorFromOverlayType(overlayType))
          );
          const svgObj = fabric.util.groupSVGElements(objects, options);
          svgObj.scaleToHeight(this.canvas.getHeight());
          (<any>svgObj).overlayType = overlayType;
          svgObj.selectable = false;
          this.canvas.add(svgObj);
          this.renderCanvas();

          this.initializedOverlays.push(overlayType);
          if (!this.activeOverlays.includes(overlayType)) {
            this.activeOverlays.push(overlayType);
          }
          callback();
        } else {
          callback(url);
        }
      });
    }
  }

  getColorFromOverlayType(type: string): string {
    switch (type.toLowerCase()) {
      case 'dermatomes':
        return '#404040';
      case 'myotomes':
        return '#cca300';
      default:
        return '#000';
    }
  }

  renderCanvas() {
    this.canvas.renderAll();
    this.canvas.renderAll.bind(this.canvas);
  }
}
