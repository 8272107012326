import { ForwardComponent } from '@shared/forward/forward.component';
import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'draw',
    // loadChildren: './auth-token/auth-token.module#AuthTokenModule'
    loadChildren: () =>
      import('./auth-token/auth-token.module').then((m) => m.AuthTokenModule),
  },
  {
    path: 'patient-portal',
    // loadChildren: './patient-portal/patient-portal.module#PatientPortalModule'
    loadChildren: () =>
      import('./patient-portal/patient-portal.module').then(
        (m) => m.PatientPortalModule
      ),
  },
  {
    path: 'research-portal',
    // loadChildren: './research-portal/research-portal.module#ResearchPortalModule'
    loadChildren: () =>
      import('./research-portal/research-portal.module').then(
        (m) => m.ResearchPortalModule
      ),
  },
  {
    path: '',
    // loadChildren: './forms/forms.module#FormsModule'
    loadChildren: () =>
      import('./forms/forms.module').then((m) => m.FormsModule),
  },
  { path: 'schedule/:id', component: ForwardComponent },
  { path: 'clinic-portal', redirectTo: 'research-portal', pathMatch: 'prefix' },
  { path: '**', redirectTo: '/patient-portal/draw', pathMatch: 'full' },
];
