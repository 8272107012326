import { tdRotateAnimation } from '@covalent/core/common';
import { ScheduleDialogComponent } from './../dialogs/schedule-dialog/schedule-dialog.component';
import { Schedule } from '@models';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss'],
  animations: [tdRotateAnimation],
})
export class ScheduleListComponent {
  @ViewChild('scheduleDialog')
  scheduleDialog!: ScheduleDialogComponent;
  @Input() patientId!: string;
  @Input() schedules!: Schedule[];
  @Input() patientPortal = false;
  @Output() change: EventEmitter<Schedule[]> = new EventEmitter<Schedule[]>();

  newSchedule() {
    if (this.patientId && !this.patientPortal) {
      this.scheduleDialog.newSchedule(this.patientId);
    }
  }

  deleteSchedule(scheduleId: string) {
    const index = this.schedules.findIndex((s) => s.Id === scheduleId);

    this.schedules.splice(index, 1);
    this.change.emit(this.schedules);
  }

  insertSchedule(schedule: Schedule) {
    if (!this.schedules) {
      this.schedules = [];
    }
    this.schedules.push(schedule);
    this.change.emit(this.schedules);
  }

  updateSchedule(schedule: Schedule) {
    if (!this.schedules) {
      this.schedules = [];
    }
    const index = this.schedules.findIndex((s) => s.Id === schedule.Id);
    if (!(index < 0)) {
      this.schedules[index] = schedule;
      this.change.emit(this.schedules);
    }
  }

  activeSchedules(): Schedule[] {
    if (!this.schedules) {
      this.schedules = [];
    }
    let date = new Date();
    date = new Date(date.setHours(0, 0, 0, 0));
    const now: number = date.getTime();
    return this.schedules
      .filter(
        (s) =>
          new Date(s.EndDate).getTime() > now &&
          new Date(s.StartDate).getTime() <= now
      )
      .sort(
        (a, b) =>
          new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
      );
  }

  commingSchedules(): Schedule[] {
    if (!this.schedules) {
      this.schedules = [];
    }
    let date = new Date();
    date = new Date(date.setHours(0, 0, 0, 0));
    const now: number = date.getTime();
    return this.schedules
      .filter(
        (s) =>
          new Date(s.EndDate).getTime() > now &&
          new Date(s.StartDate).getTime() > now
      )
      .sort(
        (a, b) =>
          new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
      );
  }
}
