import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-start-tour-dialog',
  templateUrl: './start-tour.component.html',
  styleUrls: ['./start-tour.component.scss'],
})
export class StartTourDialogComponent {
  dontShow = false;
  constructor(
    public dialogRef: MatDialogRef<StartTourDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public showSkip: boolean
  ) {}

  skip() {
    if (this.dontShow) {
      localStorage.setItem('tour-skipped', new Date().toUTCString());
    }
    this.dialogRef.close(false);
  }
}
