import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@services';

@Injectable()
export class ResearchManagerGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
    const user = this.authService.user;
    if (user && user.isResearchManager) {
      return true;
    } else if (user && user.isResearchPersonnel) {
      this.router.navigate(['/research-portal']);
      return false;
    } else if (user && user.isPatient) {
      this.router.navigate(['/patient-portal']);
      return false;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
