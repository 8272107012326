import { environment } from '@environment';
import { TemplateBundle, Template } from '@models';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class TemplateService {
  url = `${environment.api_path}api/v${environment.api_version}/Research/TemplateBundles`;
  predefinedTemplateBundles: TemplateBundle[] = [
    {
      Id: 1,
      Name: 'Male',
      Templates: [
        {
          Id: 1,
          TemplateBundleId: 1,
          Name: 'Front',
          FilePath: 'v3_3d_male_front.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 2,
          TemplateBundleId: 1,
          Name: 'Right',
          FilePath: 'v3_3d_male_right.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 3,
          TemplateBundleId: 1,
          Name: 'Back',
          FilePath: 'v3_3d_male_back.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 4,
          TemplateBundleId: 1,
          Name: 'Left',
          FilePath: 'v3_3d_male_left.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
      ],
    },
    {
      Id: 2,
      Name: 'Female',
      Templates: [
        {
          Id: 5,
          TemplateBundleId: 2,
          Name: 'Front',
          FilePath: 'v3_3d_female_front.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 6,
          TemplateBundleId: 2,
          Name: 'Right',
          FilePath: 'v3_3d_female_right.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 7,
          TemplateBundleId: 2,
          Name: 'Back',
          FilePath: 'v3_3d_female_back.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 8,
          TemplateBundleId: 2,
          Name: 'Left',
          FilePath: 'v3_3d_female_left.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
      ],
    },
    {
      Id: 3,
      Name: 'Child',
      Templates: [
        {
          Id: 9,
          TemplateBundleId: 3,
          Name: 'Front',
          FilePath: 'v3_3d_child_front.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 10,
          TemplateBundleId: 3,
          Name: 'Right',
          FilePath: 'v3_3d_child_right.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 11,
          TemplateBundleId: 3,
          Name: 'Back',
          FilePath: 'v3_3d_child_back.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 12,
          TemplateBundleId: 3,
          Name: 'Left',
          FilePath: 'v3_3d_child_left.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
      ],
    },
  ];

  additionalTemplateBundles: TemplateBundle[] = [
    {
      Id: 4,
      Name: 'Male_genitalia',
      Templates: [
        {
          Id: 12,
          TemplateBundleId: 4,
          Name: 'Front',
          FilePath: 'v3_3d_male_front_genitalia.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 2,
          TemplateBundleId: 4,
          Name: 'Right',
          FilePath: 'v3_3d_male_right.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 3,
          TemplateBundleId: 4,
          Name: 'Back',
          FilePath: 'v3_3d_male_back.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
        {
          Id: 4,
          TemplateBundleId: 4,
          Name: 'Left',
          FilePath: 'v3_3d_male_left.png',
          show: false,
          Width: 568,
          Height: 1080,
        },
      ],
    },
  ];

  _templateBundles!: TemplateBundle[];
  constructor(private http: HttpClient) {}

  get templateBundles(): Observable<TemplateBundle[]> {
    if (this._templateBundles) {
      return of(this._templateBundles);
    }
    return this.http.get<TemplateBundle[]>(this.url).pipe(
      map((x) => {
        this._templateBundles = x;
        return x;
      })
    );
  }

  get allTemplateBundles(): TemplateBundle[] {
    return this.getTemplateBundles.concat(this.additionalTemplateBundles);
  }

  get getTemplateBundles(): TemplateBundle[] {
    return this.predefinedTemplateBundles;
  }

  get getTemplateBundleNames(): string[] {
    return this.predefinedTemplateBundles.map((x) => x.Name);
  }

  getTemplate(templateId: number): Template {
    const templates: Template[] = this.allTemplateBundles
      .map((tb) => tb.Templates)
      .reduce((a, b) => a.concat(b));
    const result = templates.find((x) => x.Id === templateId);
    if (result) {
      return result;
    } else {
      throw new Error(`Cannot find Template: ${templateId}`);
    }
  }

  getTemplateBundleByName(templateName: string): TemplateBundle {
    const result = this.allTemplateBundles.find(
      (x) => x.Name.toLowerCase() === templateName.toLowerCase()
    );
    if (result) {
      return result;
    } else {
      throw new Error(`Cannot find TemplateBundle: ${templateName}`);
    }
  }

  getTemplateBundle(templateBundleId: number): TemplateBundle {
    const result = this.allTemplateBundles.find(
      (x) => x.Id === templateBundleId
    );
    if (result) {
      return result;
    } else {
      throw new Error(`Cannot find TemplateBundle: ${templateBundleId}`);
    }
  }
}
