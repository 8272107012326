import { AgePipe, TzPipe } from '@pipes';
import { TdLoadingService } from '@covalent/core/loading';
import { TdDialogService } from '@covalent/core/dialogs';
import { Chart, Patient } from '@models';
import { Component, ViewChild } from '@angular/core';
import * as moment from 'moment-timezone';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { PainService, ChartService, ResearchService } from '@services';
import { ExportSettings, ExportService } from '../../services/export.service';
import { ComponentType } from '@angular/cdk/portal';
import { TdMessageComponent } from '@covalent/core/message';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styles: ['.slide-toggle {margin: 15px 0 0 5px} .pad-sm {padding: 25px 0}'],
  viewProviders: [
    TzPipe,
    PainService,
    ResearchService,
    ChartService,
    ExportService,
    AgePipe,
  ],
})
export class ExportComponent {
  @ViewChild('exportDialog') exportDialog!: ComponentType<any>;
  @ViewChild('infoMsg') infoMsg!: TdMessageComponent;
  // accessLevel: AccessLevel;
  isOldSafari!: boolean;
  exportTypes = ['pdf', 'csv', 'png'];
  dateFormats = ['DD-MM-YYYY', 'YYYY-MM-DD'];
  timeFormats = ['24h', '12h'];
  patient!: Patient;
  initialized = false;
  dateRangeGroup: FormGroup;
  maxDate!: Date;
  minDate!: Date;
  settings: ExportSettings = {
    type: 'pdf',
    name: true,
    np_code: true,
    email: false,
    birthDate: true,
    age: false,
    gender: false,
    multiPNGs: false,
    note: false,
    dateFormat: this.dateFormats[0],
    timeFormat: this.timeFormats[0],
  };

  constructor(
    public _dialogService: TdDialogService,
    private _loadingService: TdLoadingService,
    private exportService: ExportService,
    private researchService: ResearchService,
    private http: HttpClient,
    private chartService: ChartService
  ) {
    this.dateRangeGroup = new FormGroup({
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required]),
    });
    // this.statusService.accessLevel.subscribe(x => this.accessLevel = x);

    const exportSettings = localStorage.getItem('exportSettings');
    if (exportSettings) {
      this.settings = JSON.parse(exportSettings);
      this.settings.dateFormat = this.dateFormats.includes(
        this.settings.dateFormat
      )
        ? this.settings.dateFormat
        : this.dateFormats[0];
      this.settings.timeFormat = this.timeFormats.includes(
        this.settings.timeFormat
      )
        ? this.settings.timeFormat
        : this.timeFormats[0];
    }
  }

  open(patient: Patient) {
    this.dateRangeGroup.reset();
    this._dialogService.closeAll();
    if (patient.Charts && patient.Charts.length < 1) {
      // TODO: translate
      this._dialogService.openAlert({
        message: 'No charts to export',
        width: '500px',
      });
    } else if (!this.patient) {
      this.prepareDialog(patient);
    } else {
      this._dialogService.open(this.exportDialog);
    }
  }

  filterCharts(): Chart[] {
    return this.patient.Charts.filter(
      (c) =>
        moment
          .tz(c.DateCreated, this.patient.Timezone)
          .isBetween(
            moment(this.dateRangeGroup.controls.start.value),
            moment(this.dateRangeGroup.controls.end.value).add(1, 'day')
          ) && !c.Disabled
    );
  }

  export() {
    if (!this.dateRangeGroup.valid) {
      this.infoMsg.open();
      return;
    }

    this._loadingService.register();
    localStorage.setItem('exportSettings', JSON.stringify(this.settings));
    this.patient.Charts = this.filterCharts();

    switch (this.settings.type) {
      default:
      case 'pdf':
        this.exportPDF();
        break;
      case 'csv':
        this.exportCSV();
        break;
      case 'png':
        this.convertChartsToZipPNG(this.patient);
        break;
    }
  }

  exportCSV() {
    const csvCharts = this.exportService.generateCSVCharts(
      this.patient,
      this.settings
    );
    if (csvCharts.length > 0) {
      const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
      const header = Object.keys(csvCharts[0]);
      const csv = csvCharts.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(',')
      );
      csv.unshift(header.join(','));
      const csvArray = csv.join('\r\n');
      const blob = new Blob([csvArray], { type: 'text/csv' });
      saveAs(blob, 'export.csv');
    }
    this.closeDialog();
    this._loadingService.resolve();
  }

  exportPDF() {
    const filename = this.settings.name
      ? this.patient.Fullname
      : this.settings.np_code
      ? this.patient.ShortCode
      : 'export';
    const chartsWithoutCalcs = this.patient.Charts.filter((c) => !c.TotalCount);
    const calculatedCarts: Chart[] = [];
    if (chartsWithoutCalcs.length > 0) {
      chartsWithoutCalcs.forEach((c) => {
        this.chartService.getAreaCalculations(c.Id).subscribe((res) => {
          calculatedCarts.push(res);
          if (calculatedCarts.length >= chartsWithoutCalcs.length) {
            this.patient.Charts = this.patient.Charts.filter(
              (chart) => chart.TotalCount
            ).concat(calculatedCarts);
            this.convertChartsToPNG(this.patient, filename);
          }
        });
      });
    } else if (this.patient.Charts.length > 0) {
      this.convertChartsToPNG(this.patient, filename);
    } else {
      this._loadingService.resolve();
      this._dialogService.openAlert({
        message:
          'No enabled charts in interval, please select another interval.', // TODO
        width: '500px',
      });
    }
  }

  closeDialog() {
    this._dialogService.closeAll();
  }

  async fetchCharts(patient: Patient) {
    return this.researchService
      .getPatient(patient.Id, true)
      .toPromise()
      .then((res) => (patient.Charts = res.Charts));
  }

  async prepareDialog(patient: Patient) {
    this._dialogService.open(this.exportDialog);

    if (!patient.Charts) {
      await this.fetchCharts(patient);
    }

    this.patient = JSON.parse(JSON.stringify(patient));
    const max = new Date(
      Math.max.apply(
        Math,
        patient.Charts.map((c) => new Date(c.DateCreated).getTime())
      )
    );
    const min = new Date(
      Math.min.apply(
        Math,
        patient.Charts.map((c) => new Date(c.DateCreated).getTime())
      )
    );
    min.setDate(min.getDate() - 1);
    max.setDate(max.getDate() + 1);
    this.minDate = min;
    this.maxDate = max;

    this.initialized = true;
  }

  downloadDummyExport(): void {
    const url = 'assets/data/dummy-export.pdf';
    this.downloadDummyExportFile(url).subscribe((blob) =>
      saveAs(blob, 'dummy-export.pdf')
    );
  }

  downloadDummyExportFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  convertChartsToPNG(
    patient: Patient,
    filename: string,
    exportDisabled = false
  ) {
    patient.Charts.filter((c) => !exportDisabled || !c.Disabled).map((c) =>
      this.chartService.generatePNGDataString(c, () => {
        if (
          patient.Charts.filter(
            (chart) => !chart.Disabled && chart.Base64DataUrl === undefined
          ).length === 0
        ) {
          this.exportService.base64PDFcallback(
            this.exportService.exportPDF(
              this.chartService.generateChartGroups(
                patient.Charts,
                patient.Timezone
              ),
              this.patient,
              this.settings,
              this.exportService.dateModelString(this.dateRangeGroup.controls.start.value, this.dateRangeGroup.controls.end.value)
            ),
            filename,
            (error) => {
              this._loadingService.resolve();
              this.closeDialog();
            }
          );
        }
      })
    );
  }

  convertChartsToZipPNG(patient: Patient, exportDisabled = false) {
    patient.Charts.filter((c) => !exportDisabled || !c.Disabled).map((c) =>
      this.chartService.generatePNGDataString(
        c,
        () =>
          this.exportService.zipCallback(patient, (error?: any) => {
            this.closeDialog();
            this._loadingService.resolve();
          }),
        this.settings.multiPNGs,
        568
      )
    );
  }
}
