import { Template } from '@models';
import { environment } from '@environment';
import { Chart } from '@models';
import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import { fabric } from 'fabric';

@Component({
  selector: 'app-canvas-video',
  template: '<canvas #canvas></canvas>',
})
export class CanvasVideoComponent {
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  canvas!: fabric.Canvas;
  _maxHeight!: number;
  _maxWidth!: number;
  empty = true;

  @Input() template!: Template;

  @Input()
  set dimensions(dims: { width: number; height: number }) {
    this._maxHeight = dims.height;
    this._maxWidth = dims.width;
    if (this.canvas && dims.width && dims.height) {
      let height = dims.height;
      let scale = height / this.canvas.getHeight();
      let width = scale * this.canvas.getWidth();

      if (width > dims.width) {
        width = dims.width;
        scale = width / this.canvas.getWidth();
        height = scale * this.canvas.getHeight();
      }

      /** Change dimension of canvas */
      this.canvas.setDimensions({
        height: height,
        width: width,
      });

      (<fabric.Image>this.canvas.backgroundImage).scaleToHeight(height);

      /** Rescale all objects in canvas */
      this.canvas.getObjects('path').forEach((e) => {
        if (e.top && e.left && e.scaleY && e.scaleX) {
          e.set({
            top: e.top * scale,
            left: e.left * scale,
            scaleY: e.scaleY * scale,
            scaleX: e.scaleX * scale,
            selectable: false,
          });
        }
      });

      /** Render all objects in canvas */
      this.canvas.renderAll();
      this.canvas.renderAll.bind(this.canvas);
    }
  }

  _submissionId!: string[];
  @Input()
  set charts(charts: Chart[]) {
    if (this.template) {
      if (charts.length > 0) {
        this._submissionId = charts.map((c: any) => c['SubmissionId']);
        this.initCanvas(charts);
      } else {
        this.initEmptyCanvas();
      }
    }
  }

  initCanvas(charts: Chart[]) {
    let height = this._maxHeight ? this._maxHeight : 400;
    let width = this.template.Width / (this.template.Height / height);

    if (this._maxWidth && width > this._maxWidth) {
      width = this._maxWidth;
      height = this.template.Height / (this.template.Width / width);
    }

    const canvas = new fabric.Canvas(this.canvasRef.nativeElement);
    canvas.setHeight(height);
    canvas.setWidth(width);

    canvas.selection = false;
    canvas.allowTouchScrolling = true;
    canvas.renderOnAddRemove = false;

    fabric.Image.fromURL(
      environment.templatePath + this.template.FilePath,
      (img) => {
        img.lockMovementX = true;
        img.lockMovementY = true;
        img.lockScalingX = true;
        img.lockScalingY = true;
        img.lockRotation = true;
        img.selectable = false;

        canvas.setBackgroundImage(img, () => {
          const bgImg = <fabric.Image>canvas.backgroundImage;
          bgImg.scaleToHeight(height);

          charts.forEach((c) => {
            const canvasObj = JSON.parse(c.Canvas);
            canvasObj.objects.forEach((o: any) => {
              const scale =
                (bgImg.scaleY ? bgImg.scaleY : 1) /
                canvasObj.backgroundImage.scaleY;
              o.top = o.top * scale;
              o.left = o.left * scale;
              o.scaleY = o.scaleY * scale;
              o.scaleX = o.scaleX * scale;
              fabric.Path.fromObject(o, (p: any) => {
                p.selectable = false;
                p.SubmissionId = c.SubmissionId;
                p.opacity = 0;
                canvas.add(p);
              });
            });
          });

          canvas.renderAll();
          this.canvas = canvas;
          this.canvas.renderAll.bind(this.canvas);
        });
      }
    );
  }

  initEmptyCanvas() {
    if (!this.canvas) {
      let height = this._maxHeight ? this._maxHeight : 400;
      let width = this.template.Width / (this.template.Height / height);

      if (this._maxWidth && width > this._maxWidth) {
        width = this._maxWidth;
        height = this.template.Height / (this.template.Width / width);
      }

      const canvas = new fabric.Canvas(this.canvasRef.nativeElement);
      canvas.setHeight(height);
      canvas.setWidth(width);

      canvas.selection = false;
      canvas.allowTouchScrolling = true;

      fabric.Image.fromURL(
        environment.templatePath + this.template.FilePath,
        (img) => {
          img.lockMovementX = true;
          img.lockMovementY = true;
          img.lockScalingX = true;
          img.lockScalingY = true;
          img.lockRotation = true;
          img.selectable = false;

          canvas.setBackgroundImage(img, () => {
            (<fabric.Image>canvas.backgroundImage).scaleToHeight(height);
            canvas.renderAll();
            this.canvas = canvas;
            this.canvas.renderAll.bind(this.canvas);
          });
        }
      );
    }
  }

  public changeSubmission(submissionId: string) {
    if (this.canvas) {
      if (this._submissionId && this._submissionId.includes(submissionId)) {
        this.empty = false;
        this.canvas
          .getObjects('path')
          .forEach(
            (o: any) => (o.opacity = o.SubmissionId === submissionId ? 1 : 0)
          );
        this.canvas.renderAll();
        this.canvas.renderAll.bind(this.canvas);
      } else if (!this.empty) {
        this.empty = true;
        this.canvas.getObjects('path').forEach((o) => (o.opacity = 0));
        this.canvas.renderAll();
        this.canvas.renderAll.bind(this.canvas);
      }
    }
  }
}
