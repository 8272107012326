import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-development',
  template: `
    <td-message
      #messageDemo
      color="light-blue"
      label="In Development"
      sublabel="This feature is in development and improved usability changes will occur in the near future."
    >
      <button td-message-actions mat-icon-button (click)="messageDemo.close()">
        <mat-icon>cancel</mat-icon>
      </button>
    </td-message>
  `,
})
export class MessageDevelopmentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
