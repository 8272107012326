import { PlanService } from '@services';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Plan } from '@models';

@Component({
  selector: 'app-plan-container',
  templateUrl: './plan-container.component.html',
  styleUrls: ['./plan-container.component.scss'],
})
export class PlanContainerComponent {
  @Input()
  plan!: string;
  @Output()
  planChange: EventEmitter<any> = new EventEmitter<any>();

  plans: Plan[];

  constructor(private planService: PlanService) {
    this.plans = this.planService.plans;
  }

  selectPlan(plan: Plan) {
    this.plan = plan.name;
    this.planChange.emit(plan);
  }
}
