import { ExportService, ExportSettings } from './../../services/export.service';
import { ChartComponent } from './../chart/chart.component';
import { TdLoadingService } from '@covalent/core/loading';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Chart, Patient, Submission } from '@models';
import { Component, Input, ViewChild, TemplateRef } from '@angular/core';
import { AgePipe, TzPipe } from '@pipes';
import {
  AuthService,
  ChartService,
  AccessLevel,
  PainService,
  StatusService,
} from '@services';

interface Calc {
  Color: string;
  Count: number;
}

interface Area {
  Name: string;
  Total: number;
  Calcs: Calc[];
}

@Component({
  selector: 'app-chart-container',
  templateUrl: './chart-container.component.html',
  styleUrls: ['./chart-container.component.scss'],
  viewProviders: [ChartService, ExportService, AgePipe, TzPipe],
})
export class ChartContainerComponent {
  @ViewChild('painChart') painChart!: ChartComponent;
  @ViewChild('detailDialog') detailDialog!: TemplateRef<any>;
  @Input() submission!: Submission;
  @Input() chart!: Chart;
  @Input() patient!: Patient;
  @Input() height!: number;
  @Input() width!: number;
  @Input() showScrollableOverlay = true;
  @Input() showDetails = false;
  @Input() showDisabledCharts = false;
  @Input() showDisableButton = true;
  accessLevel!: AccessLevel;
  painAreas: {
    Name: string;
    Total: number;
    Severities: { Color: string; Pixels: number }[];
  }[] = [];
  total!: number;
  areaTotal!: number;
  areas!: Area[];
  overlays!: string[];

  constructor(
    public _dialogService: MatDialog,
    private snackBar: MatSnackBar,
    private chartService: ChartService,
    private tzPipe: TzPipe,
    public auth: AuthService,
    private painService: PainService,
    private statusService: StatusService,
    private exportService: ExportService,
    private loadingService: TdLoadingService
  ) {}

  get loadingId(): string {
    return this.chart.Id + '.pain.calc';
  }

  setPainCalculations(chart: Chart) {
    this.areaTotal = this.chartService.totalCount(chart);
    this.painAreas = [];
    this.chartService.totalColorCount(chart).forEach((pa) => {
      const severity = { Color: pa.color, Pixels: pa.count };
      const name = this.painService.getPainTypeFromColor(pa.color).Name;
      const res = this.painAreas.find((x) => x.Name === name);
      if (res) {
        res.Total += severity.Pixels;
        res.Severities.push(severity);
      } else {
        this.painAreas.push({
          Name: name,
          Total: severity.Pixels,
          Severities: [severity],
        });
      }
    });

    // Fill severity array if not fill
    this.painAreas.map((p) => {
      for (const color of this.painService.findMissingColor(
        p.Severities.map((x) => x.Color)
      )) {
        p.Severities.push({
          Pixels: 0,
          Color: color,
        });
      }
      // Sort serverties
      p.Severities.sort((a, b) => this.painService.compare(a.Color, b.Color));
    });
    // Sort after pain type
    this.painAreas.sort((a, b) =>
      this.painService.compare(a.Severities[0].Color, b.Severities[0].Color)
    );
  }

  exportPDF() {
    this.loadingService.register(this.loadingId);
    const settings: ExportSettings = {
      type: 'pdf',
      name: true,
      email: true,
      birthDate: true,
      np_code: true,
      age: true,
      gender: true,
      dateFormat: 'DD-MM-YYYY',
      timeFormat: '24h',
      multiPNGs: false,
      note: true,
    };

    this.chartService.generatePNGDataString(this.chart, (chart) => {
      chart['PainLevel'] = this.submission.PainLevel;
      chart['UsualPainLevel'] = this.submission.UsualPainLevel;
      this.exportService.base64PDFcallback(
        this.exportService.exportPDF(
          this.chartService.generateChartGroups([chart], this.patient.Timezone),
          this.patient,
          settings,
          this.tzPipe.transform(chart.DateCreated, 'date')
        ),
        this.patient.Fullname,
        (error) => {
          this.loadingService.resolve(this.loadingId);
        }
      );
    });
  }

  exportPNG() {
    this.painChart.download(
      this.tzPipe.transform(this.chart.DateCreated, 'DD-MM-YYYY_HH-mm') +
        this.chart.Id
    );
  }

  getPainSeverity(color: string): string {
    return this.painService.getSeverityFromColor(color);
  }

  getPaintypeName(color: string): string {
    return this.painService.getPainTypeFromColor(color).Name;
  }

  formatPct(n: number, total = this.total, precision = 2): number {
    return (
      Math.round((Math.min(total, n) / total) * Math.pow(10, precision + 2)) /
      Math.pow(10, precision)
    );
  }

  toggleChart(chartId: string, status: boolean) {
    this.loadingService.register('toggle.chart');
    this.chartService.toggleChart(chartId, status).subscribe(
      (res) => {
        this.chart.Disabled = res.Disabled;
        this.loadingService.resolve('toggle.chart');
        this._dialogService.closeAll();
      },
      (err) => {
        this.loadingService.resolve('toggle.chart');
        this._dialogService.closeAll();
      }
    );
  }

  openDialog() {
    this.statusService.researchOrganizationAccessLevel.subscribe(
      (accessLevel) => {
        this.overlays = accessLevel.Overlays || [];
        this.accessLevel = accessLevel;
        this.checkForPainCalcs();
      }
    );
  }

  checkForPainCalcs() {
    this._dialogService.open(this.detailDialog);
    if (this.chart.TotalCount) {
      this.setPainCalculations(this.chart);
    } else {
      this.loadingService.register(this.loadingId);
      this.chartService.getAreaCalculations(this.chart.Id).subscribe(
        (res) => {
          this.setPainCalculations(res);
          this.loadingService.resolve(this.loadingId);
        },
        (error) => {
          this.snackBar.open(
            error.Message,
            error.Dismiss ? error.Dismiss : 'Dismiss'
          );
          this._dialogService.closeAll();
          this.loadingService.resolve(this.loadingId);
        }
      );
    }
  }

  toogleColor(color: string, status: boolean) {
    this.painChart.toogleColor(color, status);
  }

  toggleOverlay(overlay: string): void {
    this.loadingService.register(this.loadingId);
    this.painChart.toggleOverlay(overlay, (error?: string) => {
      this.loadingService.resolve(this.loadingId);
      if (error) {
        const message = overlay + ' overlay not found';
        this.snackBar.open(message, 'Dismiss'); // TODO
        throw new Error(message + ' for: ' + error);
      }
    });
  }
}
