import { throwError, Observable } from 'rxjs';
import { environment } from '@environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Submission, Patient, Settings } from '@models';

interface PatientOrganizationBody {
  patientId: string;
  TemplateBundleId: number;
  Summary: string;
  PatientStatus: string;
}

@Injectable()
export class PatientService {
  url = `${environment.api_path}api/v${environment.api_version}/Patients`;
  scheduleUrl = `${environment.api_path}api/v${environment.api_version}/Schedules`;
  constructor(private http: HttpClient) {}

  /** Get drawing settings for patient, authorization: Patient*/
  get drawingSettings(): Observable<Settings[]> {
    return this.http.get<Settings[]>(this.url + '/DrawingSettings');
  }

  /** Get current patient, authorization: Patient*/
  getCurrentPatient(
    includeCharts = false,
    includeSubmissions = false,
    includeSchedules = false
  ): Observable<Patient> {
    const params = new HttpParams()
      .set('includeCharts', includeCharts.toString())
      .set('includeSubmissions', includeSubmissions.toString())
      .set('includeSchedules', includeSchedules.toString());

    return this.http.get<Patient>(this.url, { params: params });
  }

  fetchChartsForPatient(
    patientId: string,
    submissionIds: string[]
  ): Observable<Submission[]> {
    let params = new HttpParams();
    submissionIds.forEach((id: string) => {
      params = params.append(`SubmissionIds[]`, id);
    });

    return this.http.get<Submission[]>(`${this.url}/${patientId}/Submissions`, {
      params: params,
    });
  }

  fetchCharts(submissionIds: string[]): Observable<Submission[]> {
    let params = new HttpParams();
    submissionIds.forEach((id: string) => {
      params = params.append(`SubmissionIds[]`, id);
    });

    return this.http.get<Submission[]>(`${this.url}/Submissions`, {
      params: params,
    });
  }

  putPatient(patient: Patient): Observable<Patient> {
    if (patient.Id === 'dummy-patient') {
      return throwError({
        Message: 'You cannot change the dummy patient',
      });
    }
    return this.http.put<Patient>(this.url + `/${patient.Id}`, patient);
  }

  postPatient(patient: Patient, isTestPatient = false): Observable<Patient> {
    return this.http.post<Patient>(
      this.url + (isTestPatient ? '/Test' : ''),
      patient
    );
  }

  changePatientInfo(body: PatientOrganizationBody): Observable<Patient> {
    if (body.patientId === 'dummy-patient') {
      return throwError({
        Message: 'You cannot change the dummy patient',
      });
    }
    return this.http.post<Patient>(this.url + '/PatientInfo', body);
  }
}
