import { tdRotateAnimation } from '@covalent/core/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { AuthToken } from '@models';
import { WindowRef } from '../windowRef';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-token-list',
  templateUrl: './token-list.component.html',
  styleUrls: ['./token-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [tdRotateAnimation],
})
export class TokenListComponent {
  activeLimit? = 3;
  inactiveLimit? = 3;
  @Input() tokens!: AuthToken[];
  @Output() newToken = new EventEmitter<number>();
  @Output() revokeToken = new EventEmitter<string>();

  constructor(
    private window: WindowRef,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  get activeTokens(): AuthToken[] {
    return this.tokens ? this.tokens.filter((x) => !x.Used && !x.Expired) : [];
  }

  get inActiveTokens(): AuthToken[] {
    return this.tokens ? this.tokens.filter((x) => x.Used || x.Expired) : [];
  }

  getLink(token: string): string {
    return `${this.window.nativeWindow.location.origin}/draw/${token}`;
  }

  copied() {
    this.translate.get('GLOBAL.copied').subscribe((copied) =>
      this.snackBar.open(copied, undefined, {
        duration: 2500,
        panelClass: ['primary-green'],
      })
    );
  }
}
