import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  transform(value: Date | moment.Moment): number {
    const today = moment();
    const birthdate = moment(value);
    return today.diff(birthdate, 'years');
  }
}
