<ng-template
  let-question
  ngFor
  let-last="last"
  [ngForOf]="questionnaire.Questions"
>
  <div fxLayout="column" [class.push-bottom]="!last">
    <mat-form-field
      *ngIf="question.Type === 'select' || question.Type === 'select-multiple'"
      [class.mat-form-field-invalid]="invalid(question)"
      fxFlex
    >
      <mat-label>{{ question.Question | translate }}</mat-label>
      <mat-select
        [(value)]="question.Answer"
        [multiple]="question.Type === 'select-multiple'"
        [required]="question.Required"
      >
        <mat-option
          *ngFor="let option of question.Options"
          [value]="option.Value"
        >
          {{ option.Name | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div
      fxFlexFill
      fxLayout="column"
      *ngIf="question.Type === 'radio-group'"
      [class.validation-error]="invalid(question)"
    >
      <label class="push-bottom pad-top-sm pad-bottom-sm">{{
        question.Question | translate
      }}</label>
      <mat-radio-group
        [fxLayout]="question.Layout"
        [fxLayoutAlign]="question.LayoutAlign"
        [fxLayoutGap]="question.LayoutGap"
        [(ngModel)]="question.Answer"
        style="padding-left: 10px"
      >
        <mat-radio-button
          *ngFor="let option of question.Options"
          color="primary"
          [value]="option.Value"
        >
          {{ option.Name | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</ng-template>
