import { Injectable } from '@angular/core';

export interface BrowserInfo {
  name: string;
  version: number | string;
}

function _navigator(): Navigator {
  // return the global navigator object
  return navigator;
}

@Injectable()
export class NavigatorRef {
  get navigator(): Navigator {
    return _navigator();
  }

  get browserInfo(): BrowserInfo {
    const ua = this.navigator.userAgent;
    let tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return {
        name: 'IE',
        version: tem[1] ? this.convert(tem[1]) : 0,
      };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return {
          name: 'Opera',
          version: this.convert(tem[1]),
        };
      }
    }
    M = M[2]
      ? [M[1], M[2]]
      : [this.navigator.appName, this.navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: this.convert(M[1]),
    };
  }

  private convert(i: string): number | string {
    const n = Number(i);
    return Number.isNaN(n) ? i : n;
  }
}
