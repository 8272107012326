import { fromEvent as observableFromEvent } from 'rxjs';
import { distinctUntilChanged, map, debounceTime } from 'rxjs/operators';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-state-selector',
  templateUrl: './state-selector.component.html',
  styleUrls: ['../shared.scss'],
})
export class StateSelectorComponent implements AfterViewInit {
  @ViewChild('searchinput') searchinput!: ElementRef;
  searchTerm!: string;
  _states!: string[];
  states!: string[];
  _iso2!: string;
  @Input() set iso2(iso2: string) {
    if (iso2) {
      if (iso2 !== this._iso2) {
        this._iso2 = iso2;
        // this._states = this.states = this.countryService.getStates(this._iso2);
      }
    } else {
      this.disabled = true;
    }
  }
  @Input() disabled = false;
  @Input() placeholder = this.iso2 ? 'Select state' : 'Select country first';
  @Input() searchPlaceholder = 'Search';
  @Input() state!: string;
  @Output() stateChange = new EventEmitter<string>();

  constructor() {
    if (!this._iso2) {
      this.disabled = true;
    }
  }

  ngAfterViewInit() {
    observableFromEvent(this.searchinput.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        map((e: any) => e.target.value),
        distinctUntilChanged()
      )
      .subscribe(() => this.filter());
  }

  filter() {
    this.states = this._states.filter(
      (s) => s.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0
    );
  }

  focus() {
    this.clearSearch();
    this.searchinput.nativeElement.focus();
  }

  clearSearch() {
    this.searchTerm = '';
    this.states = this._states;
  }

  changeState(state: string) {
    this.stateChange.emit(state);
  }
}
