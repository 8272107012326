<div fxLayout="column" style="padding-bottom: 8px">
  <span>Version: {{ version }}</span>
  <span></span>
  <span>
    {{ year }} &copy;
    <a
      class="terms"
      href="https://aglancesolutions.com/"
      target="_blank"
      rel="noopener"
    >
      Aglance Solutions ApS
    </a>
  </span>
</div>
