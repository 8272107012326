import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reversed',
})
export class ReversedPipe implements PipeTransform {
  transform(value: any[]): any[] {
    const res: any[] = [];
    value.forEach((x) => res.unshift(x));
    return res;
  }
}
