import { throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { User } from '@models';
import { Injectable } from '@angular/core';
import * as Raven from 'raven-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppTranslateService } from './app-translate.service';

@Injectable()
export class AuthService {
  _user?: User;

  constructor(
    private _http: HttpClient,
    private router: Router,
    private _translate: AppTranslateService
  ) {
    this.user = this.user;
  }

  private get isLoggedIn(): boolean {
    if (this._user && this._user.Id) {
      return true;
    } else {
      return false;
    }
  }

  get user(): User | undefined {
    return this.isLoggedIn ? this._user : this.getUser();
  }

  set user(val: User | undefined) {
    if (val) {
      this._user = new User(val);
    } else {
      this._user = undefined;
    }
  }

  private getUser(): User | undefined {
    let userString: string | null = localStorage.getItem('user');

    if (!userString) {
      this.clearUser();
      return this._user;
    }

    this.user = JSON.parse(userString);

    if (!this.isLoggedIn) {
      userString = sessionStorage.getItem('user');

      if (!userString) {
        this.clearUser();
        return this._user;
      }

      this.user = JSON.parse(userString);

      if (!this.isLoggedIn) {
        this.clearUser();
      }
    }

    if (this.isLoggedIn) {
      Raven.setUserContext({
        id: this.user?.Id,
        email: this.user?.Email,
      });
    }
    return this._user;
  }

  saveUser(
    user: User,
    remember: boolean = localStorage.getItem('user') != undefined
  ): User {
    this.user = user;
    // Change language if needed
    this._translate.setLanguageIfUnset(user.LanguageLocale);

    sessionStorage.setItem('user', JSON.stringify(this.user));

    if (remember) {
      localStorage.setItem('user', JSON.stringify(this.user));
    }

    return user;
  }

  signin(model: {
    email: string;
    password: string;
    remember: boolean;
  }): Observable<User> {
    this.clearUser();

    const loginStr = `grant_type=password&username=${encodeURIComponent(
      model.email
    )}&password=${encodeURIComponent(model.password)}`;
    return this._http
      .post<User>(environment.api_path + 'auth', loginStr, {
        headers: this.urlencodedHeader,
      })
      .pipe(map((res) => this.saveUser(res, model.remember)));
  }

  refresh(): Observable<any> {
    const refreshToken = this.user?.refresh_token;

    if (!refreshToken) {
      return throwError({ Message: 'refresh_token not found' });
    }

    const refreshStr = `grant_type=refresh_token&refresh_token=${refreshToken}`;
    return this._http
      .post<User>(environment.api_path + 'auth', refreshStr, {
        headers: this.urlencodedHeader,
      })
      .pipe(
        map((user) => {
          console.log('Auth: Token refreshed');
          return this.saveUser(user);
        })
      );
  }

  logout() {
    this.clearUser();
    this.router.navigate(['/login']);
  }

  clearUser() {
    this._user = undefined;
    localStorage.removeItem('user');
    localStorage.removeItem('lang');
    sessionStorage.removeItem('user');
  }

  private get urlencodedHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
  }
}
