import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitArray',
})
export class LimitArrayPipe implements PipeTransform {
  transform(value: any[], limit?: number): any[] {
    if (limit) {
      return value.slice(0, limit);
    } else if (limit === 0) {
      return [];
    } else {
      return value;
    }
  }
}
