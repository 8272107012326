import { TdLoadingService } from '@covalent/core/loading';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Component, ViewChild, TemplateRef } from '@angular/core';
import { AccountService } from '@services';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  viewProviders: [AccountService],
})
export class FeedbackDialogComponent {
  @ViewChild('feedbackDialog') feedbackDialog!: TemplateRef<any>;
  satisfactory!: number;

  constructor(
    private _translateService: TranslateService,
    public _dialogService: MatDialog,
    private _snackBarService: MatSnackBar,
    private _loadingService: TdLoadingService,
    private accountService: AccountService
  ) {}

  open() {
    this._dialogService.open(this.feedbackDialog);
  }

  submitFeedback(feedback: string) {
    this._loadingService.register();
    this._translateService.get('MESSAGES.dismiss').subscribe((dismiss) => {
      this.accountService
        .submitFeedback({
          Feedback: feedback,
          Satisfactory: this.satisfactory,
          LanguageLocale: this._translateService.getDefaultLang(),
        })
        .subscribe(
          (response) => {
            this._translateService
              .get('FEEDBACK.submitted')
              .subscribe((submitted) => {
                this._loadingService.resolve();
                this._snackBarService.open(submitted, undefined, {
                  duration: 2500,
                  panelClass: ['primary-green'],
                });
                this._dialogService.closeAll();
              });
          },
          (err) => {
            this._translateService.get('FEEDBACK.error').subscribe((error) => {
              this._loadingService.resolve();
              this._snackBarService.open(error, dismiss);
              this._dialogService.closeAll();
            });
          }
        );
    });
  }
}
