import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import * as Raven from 'raven-js';
import { StatusService } from '@services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';

@Injectable()
export class PatientStatusGuard implements CanActivate {
  constructor(
    private statusService: StatusService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.authService.user;
    if (user && user.isPatient) {
      const status = this.statusService
        .getPatientStatus()
        .toPromise()
        .then((_) => true)
        .catch((err) => {
          if (err.Status === 403 && err.Message === 'Terms not accepted') {
            this.router.navigate([
              '/terms',
              'patient',
              {
                under16: err.Under16,
                updated: err.PreviouslyAcceptedTerms,
              },
            ]);
            return true;
          } else if (
            err.Status === 403 &&
            err.Message === 'Account suspended'
          ) {
            Raven.captureException(new Error('No clinic'));
            this.snackBar.open('Account suspended');
            return this.restrict(state.url);
          } else {
            console.error(err);
            return this.restrict(state.url);
          }
        });
      return status;
    } else if (user && user.isResearchPersonnel) {
      this.router.navigate(['/research-portal']);
      return false;
    } else {
      return this.restrict(state.url);
    }
  }

  restrict(redirectUrl: string): boolean {
    this.router.navigate(['/login'], {
      queryParams: { redirectUrl: redirectUrl },
    });
    return false;
  }
}
