import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { AuthService } from '@services';

@Pipe({
  name: 'tz',
})
export class TzPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(value: moment.MomentInput, format = 'date', zone?: string): string {
    format = this.convertFormat(format);
    zone = zone || this.auth.user?.Timezone || moment.tz.guess();
    return moment((<any>moment.tz(value, zone))._d).format(format);
  }

  convertFormat(format: string) {
    switch (format.toLowerCase()) {
      case 'date':
        return 'DD-MM-YYYY';
      case 'time':
      case '24h':
        return 'HH:mm';
      case 'datetime':
        return 'DD-MM-YYYY HH:mm';
      case '12h':
        return 'hh:mmA';
      default:
        return format;
    }
  }
}
