<div *ngIf="showDisabledCharts || !chart.Disabled">
  <div fxLayout="row" fxLayoutAlign="center center">
    <button
      *ngIf="showDetails && chart"
      mat-raised-button
      color="accent"
      class="show-more-button"
      (click)="openDialog()"
    >
      {{ 'GLOBAL.show_more' | translate }}
    </button>
  </div>
  <div>
    <button
      *ngIf="showDisableButton"
      mat-icon-button
      class="disable-button"
      (click)="_dialogService.open(toogleChartDialog)"
      [matTooltip]="'GLOBAL.delete_chart' | translate"
    >
      <mat-icon class="mat-24 grey-color">delete_outline</mat-icon>
    </button>
    <div style="position: relative">
      <div
        class="scrollable-overlay"
        *ngIf="showScrollableOverlay"
        (dblclick)="showDetails ? openDialog() : ''"
      ></div>
      <app-chart
        *ngIf="chart"
        [height]="height"
        [width]="width"
        [chart]="chart"
        (dblclick)="showDetails ? openDialog() : ''"
      ></app-chart>
    </div>
  </div>
  <ng-template #toogleChartDialog>
    <ng-template tdLoading="toggle.chart">
      <td-dialog>
        <td-dialog-title>
          {{ 'GLOBAL.delete_chart' | translate }}
        </td-dialog-title>
        <mat-divider></mat-divider>
        <td-dialog-content>
          {{ 'GLOBAL.delete_chart_are_you_sure' | translate }}
        </td-dialog-content>
        <td-dialog-actions>
          <button mat-button #closeBtn (click)="_dialogService.closeAll()">
            {{ 'GLOBAL.cancel' | translate }}
          </button>
          <button
            mat-button
            color="primary"
            #changeBtn
            (click)="toggleChart(chart.Id, !chart.Disabled)"
          >
            {{ 'GLOBAL.yes' | translate }}
          </button>
        </td-dialog-actions>
      </td-dialog>
    </ng-template>
  </ng-template>
</div>
<ng-template #detailDialog *ngIf="showDetails && chart">
  <ng-template [tdLoading]="loadingId" tdLoadingStrategy="overlay">
    <td-dialog-title>
      <span fxFlex></span>
      <span class="menu-btn">
        <button mat-icon-button [matMenuTriggerFor]="painToggleMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </span>
    </td-dialog-title>
    <td-dialog-content
      *ngIf="chart"
      fxLayout="row"
      fxLayout.xs="column"
      style="padding-top: 10px"
    >
      <div
        fxShow
        fxHide.xs
        fxHide.sm
        style="min-width: 350px; margin-right: 10px"
      >
        <span
          fxLayoutAlign="center"
          style="font-size: 18px; margin-bottom: 8px"
        >
          {{ chart.DateCreated | tz }}
          <b style="padding: 0 15px">{{ chart.DateCreated | tz: 'time' }}</b>
          ({{ chart.DateCreated | appTimeAgo | async }})
        </span>
        <app-pain-level-bar
          [currentPainLevel]="submission.PainLevel"
          [usualPainLevel]="submission.UsualPainLevel"
          [currentPainPlaceholder]="'TOOLBOX.current' | translate"
          [usualPainPlaceholder]="'TOOLBOX.usual' | translate"
        >
        </app-pain-level-bar>
        <mat-divider class="divider-margin"></mat-divider>
        <div *ngIf="submission.Note">
          <p>
            <b>{{ 'GLOBAL.note' | translate }}: </b>
            {{ submission.Note }}
          </p>
          <mat-divider class="divider-margin"></mat-divider>
        </div>
        <div
          style="max-width: 350px"
          [style.max-height]="chart.Note ? '610px' : '670px'"
        >
          <mat-accordion>
            <ng-template
              let-painCalc
              let-first="first"
              ngFor
              [ngForOf]="painAreas"
            >
              <mat-expansion-panel [expanded]="first">
                <mat-expansion-panel-header>
                  <div
                    style="margin-right: 16px"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="space-between"
                  >
                    <div
                      class="circle"
                      [style.background]="
                        painCalc.Severities[painCalc.Severities.length - 1]
                          .Color
                      "
                    ></div>
                    <span style="font-weight: 600">{{
                      'PAINTYPES.' + painCalc.Name | translate
                    }}</span>
                    <span
                      >({{
                        formatPct(painCalc.Total, areaTotal).toFixed(2)
                      }}
                      %)</span
                    >
                  </div>
                </mat-expansion-panel-header>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <ng-template
                    let-severity
                    ngFor
                    [ngForOf]="painCalc.Severities"
                  >
                    <div
                      fxLayout="column"
                      fxLayoutAlign="center center"
                      [matTooltip]="
                        ('PAIN_SEVERITY.' + getPainSeverity(severity.Color)
                          | translate) +
                        ' ' +
                        ('PAINTYPES.' + getPaintypeName(severity.Color)
                          | translate) +
                        ' (' +
                        severity.Pixels +
                        ')'
                      "
                    >
                      <span
                        >{{
                          formatPct(severity.Pixels, areaTotal).toFixed(2)
                        }}
                        %</span
                      >
                      <ui-switch
                        [disabled]="severity.Pixels === 0"
                        [color]="severity.Color"
                        (change)="painChart.toogleColor(severity.Color, $event)"
                        [checked]="true"
                      ></ui-switch>
                      <span>{{ severity.Pixels | digits: 2 }}</span>
                    </div>
                  </ng-template>
                </div>
              </mat-expansion-panel>
            </ng-template>
          </mat-accordion>
        </div>
      </div>
      <app-chart #painChart [height]="height + 100" [chart]="chart"></app-chart>
    </td-dialog-content>
  </ng-template>
  <mat-menu #painToggleMenu="matMenu" xPosition="before">
    <app-pain-level-bar
      fxHide.gt-sm
      [currentPainLevel]="submission.PainLevel"
      [usualPainLevel]="submission.UsualPainLevel"
    >
    </app-pain-level-bar>
    <div fxHide.gt-sm class="note-container" *ngIf="submission.Note">
      <b>{{ 'GLOBAL.note' | translate }}: </b>{{ submission.Note }}
    </div>
    <button mat-menu-item (click)="exportPDF()">
      <mat-icon>picture_as_pdf</mat-icon>
      {{ 'GLOBAL.export' | translate }} PDF
    </button>
    <button mat-menu-item (click)="exportPNG()">
      <mat-icon>photo_library</mat-icon>
      {{ 'GLOBAL.export' | translate }} PNG
    </button>
    <button
      mat-menu-item
      [matMenuTriggerFor]="overlayMenu"
      *ngIf="overlays && overlays.length"
    >
      <mat-icon>grid_on</mat-icon>
      {{ 'GLOBAL.overlays' | translate }}
    </button>
    <button *ngIf="auth?.user?.isAdmin" [copy]="chart.Id" mat-menu-item>
      <mat-icon>fingerprint</mat-icon>
      Copy Chard ID
    </button>
    <div fxHide.gt-sm>
      <ng-template let-painCalc let-first="first" ngFor [ngForOf]="painAreas">
        <a
          mat-menu-item
          [matMenuTriggerFor]="painTypeMenu"
          [style.color]="
            painCalc.Severities[painCalc.Severities.length - 1].Color
          "
        >
          {{ 'PAINTYPES.' + painCalc.Name | translate }}
        </a>
        <mat-menu #painTypeMenu="matMenu">
          <ng-template
            let-severity
            let-last="last"
            ngFor
            [ngForOf]="painCalc.Severities"
          >
            <div fxLayout="column" fxLayoutAlign="center center">
              <div fxLayout="column" fxLayoutAlign="center center">
                <span
                  >{{
                    formatPct(severity.Pixels, areaTotal).toFixed(2)
                  }}
                  %</span
                >
                <ui-switch
                  [disabled]="severity.Pixels === 0"
                  [color]="severity.Color"
                  (change)="painChart.toogleColor(severity.Color, $event)"
                  [checked]="true"
                ></ui-switch>
                <span>{{ severity.Pixels | digits: 2 }}</span>
                <mat-divider *ngIf="!last"></mat-divider>
              </div>
            </div>
          </ng-template>
        </mat-menu>
      </ng-template>
    </div>
  </mat-menu>
</ng-template>
<mat-menu #overlayMenu="matMenu">
  <button mat-menu-item *ngFor="let o of overlays" (click)="toggleOverlay(o)">
    {{ 'OVERLAYS.' + o | translate }}
  </button>
</mat-menu>
