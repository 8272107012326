import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pain-slider',
  templateUrl: './pain-slider.component.html',
  styleUrls: ['./pain-slider.component.scss'],
})
export class PainSliderComponent implements OnInit {
  @Input() max = 10;
  _value = 0;
  @Output() valueChange = new EventEmitter<number>();
  @Input() set value(value: number) {
    this._value = value;
    this.valueChange.emit(value);
  }
  get value(): number {
    return this._value;
  }
  @Input() disabled = false;
  @Input() useSmileys = false;
  constructor() {}

  ngOnInit() {}

  classFromElement(elem: any) {
    const val = Number(elem.value);
    if (val < 350) {
      return 'happy';
    } else if (val < 550) {
      return 'neutral';
    } else if (val < 750) {
      return 'sad';
    } else {
      return 'bad';
    }
  }
}
