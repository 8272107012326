import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
})
export class EmptyPipe implements PipeTransform {
  transform(value: any): any {
    return value && value !== 'undefined' && value !== '' ? value : 'N/A';
  }
}
