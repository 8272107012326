<mat-expansion-panel class="app-expansion-panel" [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ 'PATIENT.auth_links' | translate }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-divider></mat-divider>
  <div>
    <mat-tab-group>
      <mat-tab #activeTabGroup [label]="'PATIENT.active_links' | translate">
        <mat-nav-list>
          <ng-template
            let-token
            let-last="last"
            ngFor
            [ngForOf]="activeTokens | limitArray: activeLimit"
          >
            <mat-list-item
              fxLayout="row"
              [matMenuTriggerFor]="tokenMenu"
              style="width: 100%"
              [ngStyle]="{ border: token.New ? '1px solid #059e74' : 'none' }"
            >
              <h3 matLine>{{ getLink(token.Token) }}</h3>
              <p matLine>
                {{ 'GLOBAL.created' | translate }}
                {{ token.DateCreated | appTimeAgo | async }} ({{
                  token.DateCreated | tz
                }})
              </p>
              <p matLine>
                {{ 'GLOBAL.expires' | translate }}
                {{ token.ExpirationDate | appTimeAgo | async }} ({{
                  token.ExpirationDate | tz
                }})
              </p>
              <mat-icon>navigate_next</mat-icon>
            </mat-list-item>
            <mat-menu #tokenMenu="matMenu">
              <button
                mat-menu-item
                [copy]="getLink(token.Token)"
                (copied)="copied()"
              >
                <mat-icon>content_copy</mat-icon
                >{{ 'GLOBAL.copy_link_to_clipboard' | translate }}
              </button>
              <button mat-menu-item (click)="revokeToken.emit(token.Token)">
                <mat-icon>replay</mat-icon
                >{{ 'PATIENT.revoke_link' | translate }}
              </button>
            </mat-menu>
            <mat-divider *ngIf="!last"></mat-divider>
          </ng-template>
          <mat-list-item
            *ngIf="activeTokens.length < 1"
            fxLayout="row"
            style="width: 100%"
          >
            <h3 matLine>{{ 'GLOBAL.no_active_links' | translate }}</h3>
          </mat-list-item>
          <mat-divider
            *ngIf="
              activeLimit &&
              activeTokens.length &&
              activeTokens.length > activeLimit
            "
          ></mat-divider>
          <mat-list-item
            *ngIf="
              activeLimit &&
              activeTokens.length &&
              activeTokens.length > activeLimit
            "
            (click)="activeLimit = activeLimit ? undefined : 3"
            style="width: 100%; height: 40px"
          >
            <div fxFlex fxLayoutAlign="center" fxLayoutGap="8px">
              <span>{{
                (activeLimit ? 'GLOBAL.show_all' : 'GLOBAL.show_less')
                  | translate
              }}</span
              ><mat-icon>{{
                activeLimit ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
              }}</mat-icon>
            </div>
          </mat-list-item>
        </mat-nav-list>
      </mat-tab>
      <mat-tab
        [label]="'PATIENT.inactive_links' | translate"
        [disabled]="inActiveTokens.length < 1"
      >
        <mat-list>
          <ng-template
            let-token
            let-last="last"
            ngFor
            [ngForOf]="inActiveTokens | limitArray: inactiveLimit"
          >
            <mat-list-item fxLayout="row" style="width: 100%">
              <h3 matLine>{{ token.Token }}</h3>
              <p matLine>
                {{ 'GLOBAL.created' | translate }}
                {{ token.DateCreated | appTimeAgo | async }} ({{
                  token.DateCreated | tz
                }})
              </p>
              <p *ngIf="token.Used" matLine>
                {{ 'GLOBAL.used' | translate }}
                {{ token.UsedDate | appTimeAgo | async }} ({{
                  token.UsedDate | tz
                }})
              </p>
              <p *ngIf="!token.Used" matLine>
                {{ 'GLOBAL.expired' | translate }}
                {{ token.ExpirationDate | appTimeAgo | async }} ({{
                  token.ExpirationDate | tz
                }})
              </p>
            </mat-list-item>
            <mat-divider *ngIf="!last"></mat-divider>
          </ng-template>
          <mat-divider
            *ngIf="
              inactiveLimit &&
              inActiveTokens.length &&
              inActiveTokens.length > inactiveLimit
            "
          ></mat-divider>
          <mat-list-item
            *ngIf="
              inactiveLimit &&
              inActiveTokens.length &&
              inActiveTokens.length > inactiveLimit
            "
            (click)="inactiveLimit = inactiveLimit ? undefined : 3"
            matRipple
            class="hover-item"
            style="width: 100%; height: 40px"
          >
            <div fxFlex fxLayoutAlign="center" fxLayoutGap="8px">
              <span>{{
                (inactiveLimit ? 'GLOBAL.show_all' : 'GLOBAL.show_less')
                  | translate
              }}</span
              ><mat-icon>{{
                inactiveLimit ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
              }}</mat-icon>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-tab>
    </mat-tab-group>
  </div>
  <mat-divider></mat-divider>
  <mat-card-actions *ngIf="activeTabGroup.isActive">
    <div mat-dialog-actions fxLayout="row">
      <span fxFlex></span>
      <button mat-button color="primary" (click)="newToken.emit(14)">
        {{ 'PATIENT.get_single_auth_link' | translate }}
      </button>
    </div>
  </mat-card-actions>
</mat-expansion-panel>
