import { AuthService } from '@services';
import { throwError, Observable } from 'rxjs';
import { environment } from '@environment';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private translate: TranslateService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.prepareRequest(request)).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          !request.params.keys().includes('skip-refresh')
        ) {
          return this.auth.refresh().pipe(
            flatMap(() => next.handle(this.prepareRequest(request))),
            catchError((errorResponse) => {
              this.router.navigate(['/login']);
              return throwError(errorResponse);
            })
          );
        } else {
          return this.throwError(error);
        }
      })
    );
  }

  private prepareRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (
      request.url.startsWith(environment.api_path) &&
      !request.url.startsWith(environment.api_path + 'auth') &&
      this.auth.user
    ) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.user.access_token}`,
          'Content-Type': 'application/json; charset=utf-8',
        },
      });
    }
    return request;
  }

  private throwError(err: HttpErrorResponse): Observable<any> {
    if (err.error) {
      // Formatting error message from ModelState
      if (err.error.ModelState) {
        const res: string[] = [];
        for (const k in err.error.ModelState) {
          if (err.error.ModelState.hasOwnProperty(k) && k !== '$id') {
            res.push(err.error.ModelState[k]);
          }
        }
        err.error.Message = res.join(', ');
      } else if (
        typeof err.error === 'string' ||
        (err.error.Message && typeof err.error.Message === 'string')
      ) {
        const errMessage =
          typeof err.error === 'string' ? err.error : err.error.Message;
        // todo: handle translated error messages
        return this.translate.get('MESSAGES').pipe(
          flatMap((MESSAGES) => {
            const error = err.error;
            error.Status = err.status;
            error.Message =
              MESSAGES && MESSAGES[errMessage]
                ? MESSAGES[errMessage]
                : errMessage;
            error.Dismiss = MESSAGES.dismiss;
            return throwError(error);
          })
        );
      }
      console.error(err.error);
      return throwError(err.error);
    } else {
      console.error(err.statusText);
      return throwError({ Message: err.statusText });
    }
  }
}
