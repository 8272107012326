import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { zones as dataTimezones, countryList } from './timezone-data';
import * as moment from 'moment-timezone';
import { startWith, map } from 'rxjs/operators';

interface TimezoneGroup {
  country: string;
  iso: string;
  showGroup?: boolean;
  zones: { zone: string; name: string }[];
}

@Component({
  selector: 'app-timezone-picker',
  template: `
    <mat-form-field class="full-width">
      <mat-select [formControl]="timezoneCtrl" [placeholder]="placeholder">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="searchControl"
            [placeholderLabel]="searchPlaceholder"
            [noEntriesFoundLabel]="noResultsLabel"
          ></ngx-mat-select-search>
        </mat-option>
        <ng-template let-option ngFor [ngForOf]="timezoneGroupsOptions | async">
          <mat-optgroup
            *ngIf="
              option.showGroup || option.zones.length > 1;
              else singleOption
            "
            [label]="option.country + ' (' + option.zones.length + ')'"
          >
            <mat-option *ngFor="let zone of option.zones" [value]="zone.zone">
              {{ zone.name }}
            </mat-option>
          </mat-optgroup>
          <ng-template #singleOption>
            <mat-option [value]="option.zones[0].zone">
              {{ option.zones[0].name }}
            </mat-option>
          </ng-template>
        </ng-template>
      </mat-select>
    </mat-form-field>
  `,
})
export class TimezonePickerComponent implements OnInit {
  timezoneCtrl = new FormControl();
  timezoneGroups!: TimezoneGroup[];
  timezoneGroupsOptions!: Observable<TimezoneGroup[]>;
  searchControl = new FormControl();

  @Output() timezoneChange = new EventEmitter<string>();
  @Output() country = new EventEmitter<string>();

  @Input() set timezone(timezone: string) {
    this.timezoneCtrl.setValue(timezone);
  }

  @Input() set disabled(value: boolean) {
    if (value) {
      this.timezoneCtrl.disable();
    } else {
      this.timezoneCtrl.enable();
    }
  }

  @Input() guess = false;
  @Input() showOffset = true;
  @Input() placeholder = 'Select timezone';
  @Input() searchPlaceholder = 'Search';
  @Input() noResultsLabel = 'No results';
  @Input() offsetName = 'UTC';

  ngOnInit() {
    this.initData();
    this.timezoneGroupsOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((query) => (query ? this.filter(query) : this.timezoneGroups.slice()))
    );
    this.timezoneCtrl.valueChanges.subscribe((value) => {
      this.timezoneChange.emit(value);
      const result = this.timezoneGroups.find(
        (x) => x.zones.find((y) => y.zone === value) != undefined
      );
      if (result) {
        this.country.emit(result.iso);
      }
    });

    if (!this.timezoneCtrl.value && this.guess) {
      const zone = moment.tz.guess();
      this.timezone = zone;
      this.timezoneChange.emit(zone);
    } else if (this.timezoneCtrl.value) {
      this.timezone = this.timezoneCtrl.value;
    }
  }

  initData() {
    this.timezoneGroups = Object.keys(dataTimezones).map((iso: string) => {
      const countryName = countryList[iso];
      const zones: string[] = dataTimezones[iso];
      const hasMultiple = zones.length > 1;
      const res = zones.map((x) => {
        return hasMultiple
          ? {
              zone: x,
              name: `${this.formatTimezoneString(x)} ${this.offsetOfTimezone(
                x
              )}`,
            }
          : {
              zone: x,
              name: countryName + ` ${this.offsetOfTimezone(x)}`,
            };
      });
      return <TimezoneGroup>{ iso: iso, country: countryName, zones: res };
    });
  }

  filter(query: string): TimezoneGroup[] {
    const result: TimezoneGroup[] = [];

    for (const z of this.timezoneGroups) {
      if (z.country && z.country.toLowerCase().includes(query.toLowerCase())) {
        result.push(z);
      } else {
        const results = z.zones.filter((x) =>
          x.name.toLowerCase().includes(query.toLowerCase())
        );
        if (results.length > 0) {
          z.zones = results;
          z.showGroup = z.zones.length > 1;
          result.push(z);
        }
      }
    }

    return result;
  }

  formatTimezoneString(zone: string): string {
    const arr = zone.split('/');
    return arr[arr.length - 1].replace('_', ' ');
  }

  offsetOfTimezone(zone: string): string {
    if (!this.showOffset) {
      return '';
    }
    let offset = moment.tz(zone).utcOffset();
    const neg = offset < 0;
    if (neg) {
      offset = -1 * offset;
    }
    const hours = Math.floor(offset / 60);
    const minutes = (offset / 60 - hours) * 60;
    return `(${this.offsetName}${neg ? '-' : '+'}${this.rjust(
      hours.toString(),
      2
    )}:${this.rjust(minutes.toString(), 2)})`;
  }

  private rjust(string: string, width: number, padding = '0'): string {
    padding = padding || ' ';
    padding = padding.substr(0, 1);
    if (string.length < width) {
      return padding.repeat(width - string.length) + string;
    } else {
      return string;
    }
  }
}
