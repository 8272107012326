import { Component, Input, Output, EventEmitter } from '@angular/core';
import { fabAnimations } from './fab.animations';

interface IButton {
  name: string;
  icon: string;
}

@Component({
  selector: 'app-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
  animations: [fabAnimations],
})
export class FabComponent {
  @Output() onSelect = new EventEmitter<string>();
  @Input() clickToDismiss = false;

  fabButtons: IButton[] = [
    {
      name: 'first',
      icon: 'last_page',
    },
    {
      name: 'next',
      icon: 'chevron_right',
    },
    {
      name: 'play',
      icon: 'play_arrow',
    },
    {
      name: 'prev',
      icon: 'chevron_left',
    },
    {
      name: 'last',
      icon: 'first_page',
    },
  ];
  buttons: IButton[] = [];
  active = false;

  constructor() {}

  showItems() {
    this.active = true;
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.active = false;
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }
}
