import { Submission } from '@models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environment';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';

@Injectable()
export class SubmissionService {
  url = `${environment.api_path}api/v${environment.api_version}/Submissions`;

  constructor(private http: HttpClient) {}

  postForOrganization(
    patientId: string,
    submission: Submission
  ): Observable<any> {
    if (patientId === 'dummy-patient') {
      return throwError({
        Message: 'You cannot submit charts to the dummy patient',
      });
    }
    return this.http.post(`${this.url}/Organization/${patientId}`, submission);
  }

  postForPatient(
    submission: Submission,
    organisationIds: string[]
  ): Observable<any> {
    return this.http.post(this.url + '/Patient', submission);
  }

  postWithToken(token: string, submission: Submission) {
    return this.http.post(`${this.url}/AuthToken/${token}`, submission);
  }
}
