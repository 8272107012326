<ng-template #feedbackDialog>
  <td-dialog>
    <td-dialog-title>
      {{ 'FEEDBACK.feedback' | translate }}
    </td-dialog-title>
    <td-dialog-content class="mat-subhead tc-grey-700">
      <p>{{ 'FEEDBACK.satisfaction' | translate }}</p>
      <div fxLayout="column" fxLayoutAlign="space-between stretch">
        <button
          mat-raised-button
          (click)="satisfactory = -2"
          [disabled]="satisfactory === -2"
        >
          <img
            class="emoji-icon"
            alt="emoji icon bad"
            src="assets/img/icons/bad.png"
          />
          <span>{{ 'FEEDBACK.not_good' | translate }}</span>
        </button>
        <button
          mat-raised-button
          (click)="satisfactory = -1"
          [disabled]="satisfactory === -1"
        >
          <img
            class="emoji-icon"
            alt="emoji icon sad"
            src="assets/img/icons/sad.png"
          />
          <span>{{ 'FEEDBACK.it_could_be_better' | translate }}</span>
        </button>
        <button
          mat-raised-button
          (click)="satisfactory = 0"
          [disabled]="satisfactory === 0"
        >
          <img
            class="emoji-icon"
            alt="emoji icon neutral"
            src="assets/img/icons/neutral.png"
          />
          <span>{{ 'FEEDBACK.neutral' | translate }}</span>
        </button>
        <button
          mat-raised-button
          (click)="satisfactory = 1"
          [disabled]="satisfactory === 1"
        >
          <img
            class="emoji-icon"
            alt="emoji icon happy"
            src="assets/img/icons/happy.png"
          />
          <span>{{ 'FEEDBACK.its_cool' | translate }}</span>
        </button>
        <button
          mat-raised-button
          (click)="satisfactory = 2"
          [disabled]="satisfactory === 2"
        >
          <img
            class="emoji-icon"
            alt="emoji icon love it"
            src="assets/img/icons/love_it.png"
          />
          <span>{{ 'FEEDBACK.i_love_it' | translate }}</span>
        </button>
      </div>
      <mat-divider></mat-divider>
      <p>{{ 'FEEDBACK.feedback_text' | translate }}</p>
      <mat-form-field fxFlexFill>
        <textarea
          #feedback
          matInput
          [placeholder]="'FEEDBACK.feedback' | translate"
        ></textarea>
      </mat-form-field>
    </td-dialog-content>
    <td-dialog-actions>
      <button
        mat-button
        #closeBtn
        (keydown.arrowright)="changeBtn.focus()"
        (click)="_dialogService.closeAll()"
      >
        {{ 'GLOBAL.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        #changeBtn
        color="primary"
        (keydown.arrowleft)="closeBtn.focus()"
        (click)="submitFeedback(feedback.value)"
        [disabled]="feedback.value.length < 1 && satisfactory === undefined"
      >
        {{ 'GLOBAL.submit' | translate }}
      </button>
    </td-dialog-actions>
  </td-dialog>
</ng-template>
