<tour-step-template>
  <ng-template let-step="step">
    <mat-card (click)="$event.stopPropagation()" class="tour-card">
      <mat-card-title
        *ngIf="step?.icon || step?.title"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div
          *ngIf="step?.icon"
          [ngClass]="{ 'border-btn': !step?.circleColor }"
        >
          <button
            mat-icon-button
            [ngStyle]="{
              background: step?.circleColor,
              color: step?.circleColor ? 'white' : ''
            }"
          >
            <mat-icon [style.color]="step?.iconColor">{{
              step?.icon
            }}</mat-icon>
          </button>
        </div>
        <span *ngIf="step?.title" style="margin-left: 5px">{{
          'TOUR.' + step?.title + '.title' | translate
        }}</span>
      </mat-card-title>
      <mat-card-content>
        <img
          *ngIf="step?.image"
          [src]="step?.image"
          alt="Tour step"
          style.xs="max-width: 180px"
        />
        <p>{{ 'TOUR.' + step?.title + '.content' | translate }}</p>
      </mat-card-content>
      <mat-card-actions fxLayout="row">
        <button
          mat-mini-fab
          color="secondary"
          [disabled]="!tourService.hasPrev(step)"
          (click)="tourService.prev()"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button fxFlex mat-button (click)="tourService.end()">
          {{ 'GLOBAL.end' | translate }}
        </button>
        <button
          mat-mini-fab
          color="primary"
          [disabled]="!tourService.hasNext(step)"
          (click)="tourService.next()"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-template>
</tour-step-template>
