import { TranslateService } from '@ngx-translate/core';
import { TdLoadingService } from '@covalent/core/loading';
import { TdDialogService } from '@covalent/core/dialogs';
import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScheduleService, ResearchService, StatusService } from '@services';
import { Schedule } from '@models';
import { ComponentType } from '@angular/cdk/portal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  viewProviders: [ScheduleService, ResearchService],
})
export class ScheduleDialogComponent {
  @ViewChild('newScheduleDialog')
  newScheduleDialog!: ComponentType<any>;
  @Input() disabled = false;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() new: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<string> = new EventEmitter<string>();

  hasAccess = false;
  edit = false;
  errorMessage?: string;
  jsonSchedule!: string;
  dateRangeGroup: FormGroup;
  schedule: Schedule = <Schedule>{};

  intervals = ['daily', 'weekly', 'monthly'];
  // allowed hours [6-23]
  hours = Array.from({ length: 18 }, (_, i) => i + 6);
  allowedHours = 3;
  weekdays = [1, 2, 3, 4, 5, 6, 0];
  // all days [1-31]
  days = Array.from({ length: 31 }, (_, i) => i + 1);
  minDate = new Date();

  constructor(
    private scheduleService: ScheduleService,
    private researchService: ResearchService,
    public _dialogService: TdDialogService,
    private _loadingService: TdLoadingService,
    private statusService: StatusService,
    private snackBar: MatSnackBar,
    private _translate: TranslateService
  ) {
    this.statusService.researchOrganizationAccessLevel.subscribe(
      (x) => (this.hasAccess = x.EmailReminders)
    );

    this.dateRangeGroup = new FormGroup({
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required]),
    });
  }

  requestAccess() {
    this._loadingService.register();
    this.researchService.requestFeatures(['EmailReminders']).subscribe((res) =>
      this._translate.get('PAYMENT.request_sent').subscribe(
        (x) => {
          this.snackBar.open(x, undefined, { duration: 2500 });
          this._loadingService.resolve();
          this._dialogService.closeAll();
        },
        (err) => {
          this.snackBar.open(err.Message, undefined, { duration: 2500 });
          this._loadingService.resolve();
        }
      )
    );
  }

  generateSchedule(): Schedule {
    const schedule = this.schedule;
    schedule.StartDate = moment(this.dateRangeGroup.controls.start.value).format('YYYY-MM-DD');
    schedule.EndDate = moment(this.dateRangeGroup.controls.end.value).format('YYYY-MM-DD');
    return schedule;
  }

  changeInterval(interval: string) {
    switch (interval) {
      case 'daily':
        this.schedule.Reminders = [this.hours[2]];
        break;
      case 'weekly':
        this.schedule.Reminders = [this.weekdays[0]];
        break;
      case 'monthly':
        this.schedule.Reminders = [this.days[0]];
        break;
      default:
        this.schedule.Reminders = undefined;
        break;
    }
  }

  deleteSchedule() {
    this._translate.get('DIALOGS.delete_promt').subscribe((delete_promt) => {
      this._dialogService
        .openConfirm({
          title: delete_promt.title + delete_promt.schedule,
          message: delete_promt.message + delete_promt.schedule + '?',
          acceptButton: delete_promt.acceptButton,
          cancelButton: delete_promt.cancelButton,
          width: '500px',
        })
        .afterClosed()
        .subscribe((accepted) => {
          if (accepted) {
            this._loadingService.register();
            this.scheduleService.deleteSchedule(this.schedule.Id).subscribe(
              (res) => {
                this._loadingService.resolve();
                this._dialogService.closeAll();
                this.delete.emit(this.schedule.Id);
              },
              (error) => {
                this._loadingService.resolve();
                this.errorMessage = error.Message;
              }
            );
          }
        });
    });
  }

  updateSchedule() {
    this.errorMessage = undefined;
    const schedule = this.generateSchedule();
    if (JSON.stringify(schedule) !== this.jsonSchedule) {
      if (schedule.StartDate && schedule.EndDate) {
        this._loadingService.register();
        this.scheduleService.putSchedule(schedule).subscribe(
          (res) => {
            this._loadingService.resolve();
            this._dialogService.closeAll();
            this.change.emit(res);
          },
          (error) => {
            this._loadingService.resolve();
            if (error.Message.includes('Overlapping schedule')) {
              this._translate
                .get('MESSAGES.overlapping_schedules')
                .subscribe((res) => (this.errorMessage = res));
            } else {
              this._translate
                .get('MESSAGES.something_went_wrong')
                .subscribe((res) => (this.errorMessage = res));
            }
          }
        );
      }
    } else {
      this._dialogService.closeAll();
    }
  }

  saveSchedule() {
    this.errorMessage = undefined;
    const schedule = this.generateSchedule();
    if (schedule.StartDate && schedule.EndDate) {
      this._loadingService.register();
      this.scheduleService.postSchedule(schedule).subscribe(
        (res) => {
          this._loadingService.resolve();
          this._dialogService.closeAll();
          this.new.emit(res);
        },
        (error) => {
          this._loadingService.resolve();
          if (error.Message && error.Message.includes('Overlapping schedule')) {
            this._translate
              .get('MESSAGES.overlapping_schedules')
              .subscribe((res) => (this.errorMessage = res));
          } else {
            this._translate
              .get('MESSAGES.something_went_wrong')
              .subscribe((res) => (this.errorMessage = res));
          }
        }
      );
    }
  }

  open(schedule: Schedule, patientId: string) {
    this._dialogService.closeAll();
    if (schedule) {
      this.edit = true;
      this.errorMessage = undefined;
      this.schedule = JSON.parse(JSON.stringify(schedule));
      this.schedule.PatientId = patientId;
      this.dateRangeGroup.setValue({
        start: new Date(schedule.StartDate),
        end: new Date(schedule.EndDate)
      });
      this.jsonSchedule = JSON.stringify(this.generateSchedule());
      this._dialogService.open(this.newScheduleDialog);
    }
  }

  newSchedule(patientId: string) {
    this._dialogService.closeAll();
    if (patientId) {
      this.edit = false;
      this.errorMessage = undefined;
      this.schedule = <Schedule>{};
      this.schedule.PatientId = patientId;
      this.dateRangeGroup.reset();
      this._dialogService.open(this.newScheduleDialog);
    }
  }
}
