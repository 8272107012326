import { ForwardComponent } from './shared/forward/forward.component';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { environment } from '@environment';
import { SharedModule } from '@shared/shared.module';
import { routes } from './app.routes';
import { AppComponent } from './app.component';

/******************* ANGULAR ************************/
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  BrowserModule,
  // HammerGestureConfig,
  // HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import * as Raven from 'raven-js';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { Angulartics2Module } from 'angulartics2';
import { AuthInterceptor } from './auth.interceptor';
import { TourStepComponent } from './shared/tour-step/tour-step.component';
import { AuthService } from './services/auth.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '/translation.json');
}

Raven.config(
  `https://${environment.sentry_key}@sentry.io/${environment.sentry_app}`,
  {
    release: environment.version,
  }
).install();

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(err.message)) {
      // force refresh off new chucks
      window.location.reload();
    } else {
      // sent error to Sentry
      Raven.captureException(err);
    }
  }
}

// export interface HammerInstance {
//   on(eventName: string, callback?: Function): void;
//   off(eventName: string, callback?: Function): void;
// }

// @Injectable()
// export class HammerConfig extends HammerGestureConfig {
//   events: string[] = ['pinch pinchstart'];
//   overrides: { [key: string]: Object } = {};
//   buildHammer(element: HTMLElement): HammerInstance {
//     const mc = new Hammer(element);
//     mc.get('pinch').set({ enable: true });
//     for (const eventName in this.overrides) {
//       if (this.overrides.hasOwnProperty(eventName)) {
//         mc.get(eventName).set(this.overrides[eventName]);
//       }
//     }

//     return mc;
//   }
// }

@NgModule({
  declarations: [AppComponent, ForwardComponent, TourStepComponent],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
    }),
    BrowserAnimationsModule,
    SharedModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TourMatMenuModule.forRoot(),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearQueryParams: true,
        clearIds: true,
        // regex for all numeric ids, token of lengths 44 and UUID
        /*
        idsRegExp: new RegExp(
          /^[0-9]+$|^[0-9a-zA-Z]{44}$|^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
        )
        */
      },
    }),
  ],
  providers: [
    AuthService,
    SharedModule,
    environment.production
      ? { provide: ErrorHandler, useClass: GlobalErrorHandler }
      : [],
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
