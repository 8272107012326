export const environment = {
  production: false,
  version: require('../../../../package.json').version + '-dev',
  templatePath: 'assets/img/templates/',
  overlayPath: 'assets/img/overlays/',
  api_path: 'https://api.navigatepain.com/',
  api_version: '1',
  sentry_key: '7be0c290b5784c18a88ff9210a6656bd',
  sentry_app: '362646',
  google_analytics_key: 'UA-102907717-4',
  app_langs: ['en', 'es', 'da', 'de', 'fr', 'it', 'pl', 'tr'],
  gender_list: ['Female', 'Male', 'Other'],
};
