<mat-form-field matLine class="full-width">
  <mat-select
    matLine
    [disabled]="disabled"
    [placeholder]="placeholder"
    (click)="focus()"
    (change)="changeCountry($event.value)"
    [(ngModel)]="country"
    name="country"
    class="full-width"
  >
    <div class="search_container">
      <input
        #searchinput
        class="searchInput"
        [(ngModel)]="searchTerm"
        type="search"
        [placeholder]="searchPlaceholder"
      />
      <div class="search_results">
        <ng-template let-c ngFor [ngForOf]="countries">
          <mat-option [value]="c">
            {{ c.name }}
          </mat-option>
        </ng-template>
      </div>
    </div>
  </mat-select>
</mat-form-field>
