import { map } from 'rxjs/operators';
import { environment } from '@environment';
import { Personnel } from '@models';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PersonnelService {
  url = `${environment.api_path}api/v${environment.api_version}/Personnels`;
  constructor(private auth: AuthService, private http: HttpClient) {}

  postPersonnel(personnel: Personnel) {
    return this.http.post<Personnel>(this.url, personnel);
  }

  putPersonnel(personnel: Personnel) {
    return this.http.put(this.url + `/${personnel.Id}`, personnel).pipe(
      map(() => {
        const user = this.auth.user;
        if (user && personnel.Id === user.Id) {
          user.Fullname = personnel.Fullname;
          user.Timezone = personnel.Timezone;
          user.LanguageLocale = personnel.LanguageLocale;
          this.auth.saveUser(user);
        }
      })
    );
  }

  getPersonnels(): Observable<Personnel[]> {
    return this.http.get<Personnel[]>(this.url);
  }

  deletePersonnel(id: string): Observable<any> {
    return this.http.delete(this.url + `/${id}`);
  }
}
